import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  clearUserNameAndTokenInLocalStorage,
  getUserNameFromLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
import BlockUi from "@availity/block-ui";
import { v4 as uuidv4 } from "uuid";
import { useForm, Controller } from "react-hook-form";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function EditPOItemModal(props) {
  const {
    rowData,
    onClose,
    handleShowAlert,
    supplierList,
    unitOfMeasurementsList,
    handleEditItemList,
    itemList,
    orderType,
  } = props;
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ProductId: rowData.PRODUCT_ID,
      CostPrice: rowData.COST_PRICE,
      SupplierId: rowData.SUPPLIER_ID,
    },
  });
  const [ItemId] = useState(rowData.ITEM_ID);
  const [BrandList, setBrandList] = useState([]);
  // const [Brand, setSupplierId] = useState(rowData.SUPPLIER_ID);
  // const [SupplierName, setSupplierName] = useState(rowData.SUPPLIER_NAME);
  const [SupplierList, setSupplierList] = useState([]);
  const [SupplierId, setSupplierId] = useState(rowData.SUPPLIER_ID);
  const [ProductList, setProductList] = useState([]);
  const [ProductId, setProductId] = useState(rowData.PRODUCT_ID);
  const [CurrentProductId] = useState(rowData.PRODUCT_ID);
  const [CurrentSupplierId] = useState(rowData.SUPPLIER_ID);
  const [SupplierItemCode, setSupplierItemCode] = useState(
    rowData.SUPPLIER_ITEM_CODE
  );
  const [SupplierItemName, setSupplierItemName] = useState(
    rowData.SUPPLIER_ITEM_NAME
  );
  const [ProductName, setProductName] = useState(rowData.PRODUCT_NAME);
  const [OrderQuantity, setOrderQuantity] = useState(rowData.ITEM_QTY);
  const [isRestockRequired, setIsRestockRequired] = useState(
    rowData.RESTOCK_REQ === "Y" ? true : false
  );
  const [RestockQuantity, setRestockQuantity] = useState(rowData.RESTOCK_QTY);
  const [TargetDate, setTargetDate] = useState(rowData.RESTOCK_DEADLINE);
  const [Brand, setBrand] = useState(rowData.BRAND);
  const [UnitOfMeasurements, setUnitOfMeasurements] = useState(
    rowData.UNIT_MEASURE
  );
  const [UnitPrice, setUnitPrice] = useState(rowData.UNIT_PRICE);
  const [MinSellPrice, setMinSellPrice] = useState(rowData.MIN_SELL_PRICE);
  const [SellingPrice, setSellingPrice] = useState(rowData.SELLING_PRICE);
  const [CostPrice, setCostPrice] = useState(rowData.COST_PRICE);
  const [blocking, setBlocking] = useState(false);



  useEffect(() => {
    fetch(config.brand_list_link, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        if (
          jsonData.success !== true &&
          jsonData.message === "Access Denied! Invalid token"
        ) {
          clearUserNameAndTokenInLocalStorage();
          throw new Error(jsonData.message);
        } else if (jsonData.success) {
          return jsonData.data;
        } else {
          throw new Error(jsonData.message);
        }
      })
      .then((data) => {
        setBrandList(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (SupplierId !== "") {
      handleToggleBlocking(true);
      fetch(config.product_by_supplier_list_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getTokenFromLocalStorage(),
        },
        body: JSON.stringify({
          supplier_id: SupplierId,
        }),
      })
        .then((response) => response.json())
        .then((jsonData) => {
          console.log(jsonData);
          if (
            jsonData.success !== true &&
            jsonData.message === "Access Denied! Invalid token"
          ) {
            clearUserNameAndTokenInLocalStorage();
            throw new Error(jsonData.message);
          } else if (jsonData.success) {
            return jsonData.data;
          } else {
            throw new Error(jsonData.message);
          }
        })
        .then((data) => {
          console.log(data);
          setProductList(data);
        })
        .catch((error) => console.error(error))
        .finally(()=>{
          handleToggleBlocking(false);
        });
    }
  }, [SupplierId]);

  useEffect(() => {
    if (ProductId !== "") {
      fetch(config.supplier_by_product_list_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getTokenFromLocalStorage(),
        },
        body: JSON.stringify({
          product_id: ProductId,
        }),
      })
        .then((response) => response.json())
        //   .then(res => console.log(res))
        .then((jsonData) => {
          if (
            jsonData.success !== true &&
            jsonData.message === "Access Denied! Invalid token"
          ) {
            clearUserNameAndTokenInLocalStorage();
            throw new Error(jsonData.message);
          } else if (jsonData.success) {
            return jsonData.data;
          } else {
            throw new Error(jsonData.message);
          }
        })
        .then((data) => {
          setSupplierList(data);
          // setValue("ProductId", "");
          // trigger("ProductId")
        })
        .catch((error) => console.error(error));
    }
  }, [ProductId]);
  // useEffect(() => {
  //   fetch(config.supplier_list_link, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + getTokenFromLocalStorage(),
  //     },
  //     body: JSON.stringify({}),
  //   })
  //     .then((response) => response.json())
  //     .then((jsonData) => {
  //       if (jsonData.message === "Access Denied! Invalid token") {
  //         clearUserNameAndTokenInLocalStorage();
  //         throw new Error(jsonData.message);
  //       }
  //       return jsonData;
  //     })
  //     .then((data) => {
  //       setSupplierList(data.data);
  //       console.log(data);
  //     })
  //     .catch((error) => console.error(error));
  // }, []);

  const handleToggleBlocking = (value) => {
    setBlocking(value);
  };

  const handleCheckboxChange = () => {
    setIsRestockRequired(!isRestockRequired);

    setRestockQuantity(0);
    setValue("RestockQuantity", 0);
    setTargetDate("");
    setValue("TargetDate", "");
    trigger("RestockQuantity");
    trigger("TargetDate");
  };

  const handleAddItem = () => {
    var objData = {
      ITEM_ID: ItemId,
      // SUPPLIER_ID: Brand,
      // SUPPLIER_NAME: SupplierName,
      SUPPLIER_ID: SupplierId,
      PRODUCT_ID: ProductId,
      PRODUCT_NAME: ProductName,
      ITEM_QTY: OrderQuantity,
      UNIT_MEASURE: UnitOfMeasurements !== "" ? UnitOfMeasurements : "",
      BRAND: Brand !== "" ? Brand : "",
      COST_PRICE: CostPrice !== "" ? CostPrice : "",
    };
    console.log(objData);
    handleEditItemList(objData);
    onClose();
  };

  return (
    <Modal show={true} onHide={onClose} size="lg" backdrop="static" centered>
      <BlockUi blocking={blocking}>
        <Form onSubmit={handleSubmit(handleAddItem)}>
          <Modal.Header closeButton className="px-4">
            <Modal.Title className="ms-auto">
              Edit Purchase Order Item (Adhoc)
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-2 justify-content-center">
              <Col md="7">
                <Form.Group as={Row} controlId="ProductId">
                  <Form.Label column sm="5" className="fw-bold">
                    Product
                  </Form.Label>
                  <Col sm="7">
                    <Controller
                      name="ProductId"
                      control={control}
                      rules={{ required: "This field is required" }}
                      render={({ field }) => (
                        <Form.Select
                          {...field}
                          onChange={(e) => {
                            setProductId(e.target.value);
                            setProductName(
                              e.target.options[e.target.selectedIndex].text
                            );
                            setValue("ProductId", e.target.value);
                            setSupplierId("");
                            setValue("SupplierId", "");
                            const supplierDetails = ProductList.find(
                              (product) => product.PRODUCT_ID === e.target.value
                            );
                            setBrand(
                              supplierDetails && supplierDetails.BRAND !== null
                                ? supplierDetails.BRAND
                                : ""
                            );
                            setMinSellPrice(
                              supplierDetails &&
                                supplierDetails.MIN_SELL_PRICE !== null
                                ? supplierDetails.MIN_SELL_PRICE
                                : ""
                            );
                            setUnitOfMeasurements(
                              supplierDetails &&
                                supplierDetails.UNIT_MEASURE !== null
                                ? supplierDetails.UNIT_MEASURE
                                : ""
                            );
                            setUnitPrice(
                              supplierDetails &&
                                supplierDetails.UNIT_PRICE !== null
                                ? supplierDetails.UNIT_PRICE
                                : ""
                            );
                            setSupplierItemCode(
                              supplierDetails &&
                                supplierDetails.SUPPLIER_ITEM_CODE !== null
                                ? supplierDetails.SUPPLIER_ITEM_CODE
                                : ""
                            );
                            setSupplierItemName(
                              supplierDetails &&
                                supplierDetails.SUPPLIER_ITEM_NAME !== null
                                ? supplierDetails.SUPPLIER_ITEM_NAME
                                : ""
                            );
                            trigger("ProductId");
                          }}
                          className={`form-select ${
                            errors.ProductId ? "is-invalid" : ""
                          }`}
                        >
                          <option key={""} value="">
                            Please select product name
                          </option>
                          {ProductList.map((items) => (
                            <option
                              key={items.PRODUCT_ID}
                              value={items.PRODUCT_ID}
                            >
                              {items.PRODUCT_NAME}
                            </option>
                          ))}
                          {/* {ProductList.map((items) =>
                          items.PRODUCT_STATUS === "A" &&
                          itemList.some(
                            (item) => item.PRODUCT_ID === items.PRODUCT_ID
                          ) === false ? (
                            <option
                              key={items.PRODUCT_ID}
                              value={items.PRODUCT_ID}
                            >
                              {items.PRODUCT_NAME}
                            </option>
                          ) : (
                            ""
                          )
                        )} */}
                        </Form.Select>
                      )}
                    />
                    {errors.ProductId && (
                      <Form.Text className="text-danger">
                        {errors.ProductId.message}
                      </Form.Text>
                    )}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2 justify-content-center">
              <Col md="7">
                <Form.Group as={Row} controlId="Brand">
                  <Form.Label column sm="5" className="fw-bold">
                    Brand
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control readOnly disabled type="text" value={Brand} />
                  </Col>
                </Form.Group>
              </Col>
            </Row>

            {/* <Row className="mb-2 justify-content-center">
            <Col md="7">
              <Form.Group as={Row} controlId="Brand">
                <Form.Label column sm="5" className="fw-bold">
                  Brand
                </Form.Label>
                <Col sm="7">
                  <Form.Control readOnly disabled type="text" value={Brand} />
                </Col>
              </Form.Group>
            </Col>
          </Row> */}
            <Row className="mb-2 justify-content-center">
              <Col md="7">
                <Form.Group as={Row} controlId="Brand">
                  <Form.Label column sm="5" className="fw-bold">
                    UOM
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      readOnly
                      disabled
                      type="text"
                      value={UnitOfMeasurements}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2 justify-content-center">
              <Col md="7">
                <Form.Group as={Row} controlId="SupplierItemCode">
                  <Form.Label column sm="5" className="fw-bold">
                    Supplier Item Code
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      readOnly
                      disabled
                      type="text"
                      value={SupplierItemCode}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2 justify-content-center">
              <Col md="7">
                <Form.Group as={Row} controlId="SupplierItemName">
                  <Form.Label column sm="5" className="fw-bold">
                    Supplier Item Name
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      readOnly
                      disabled
                      type="text"
                      value={SupplierItemName}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2 justify-content-center">
              <Col md="7">
                <Form.Group as={Row} controlId="OrderQuantity">
                  <Form.Label column sm="5" className="fw-bold">
                    Order Quantity
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      type="text"
                      placeholder="quantity"
                      value={OrderQuantity}
                      onInput={(e) => setOrderQuantity(e.target.value)}
                      className={`form-control ${
                        errors.OrderQuantity ? "is-invalid" : ""
                      }`}
                      {...register("OrderQuantity", {
                        required: "This field is required",
                        pattern: {
                          value: /^[1-9]\d*$/,
                          message: "Needs positive integer value",
                        },
                      })}
                    />
                    {errors.OrderQuantity && (
                      <Form.Text className="text-danger">
                        {errors.OrderQuantity.message}
                      </Form.Text>
                    )}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2 justify-content-center">
              <Col md="7">
                <Form.Group as={Row} controlId="Brand">
                  <Form.Label column sm="5" className="fw-bold">
                    Cost Price
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      type="text"
                      placeholder="Cost Price"
                      value={CostPrice}
                      className={`form-control ${
                        errors.CostPrice ? "is-invalid" : ""
                      }`}
                      onInput={(e) => setCostPrice(e.target.value)}
                      {...register("CostPrice", {
                        required: "This field is required",
                        pattern: {
                          value: /^(?:\d+|\d*\.\d{1,2})$/,
                          // value: /^(?!^(\d+|\d*\.\d{0,1})$)\d+(\.\d{1,2})?$/,
                          message: "Needs integer or 2 decimal value",
                        },
                        // validate: (value) => {
                        //   const intValue = parseInt(value, 10);
                        //   return (
                        //     intValue <= OrderQuantity ||
                        //     `Enter a positive integer up to ${OrderQuantity}`
                        //   );
                        // },
                      })}
                    />
                    {errors.CostPrice && (
                      <Form.Text className="text-danger">
                        {errors.CostPrice.message}
                      </Form.Text>
                    )}
                    {/* 
                    <Form.Control
                      
                      onInput={(e) => setCostPrice(e.target.value)}
                      type="text"
                      value={CostPrice}
                    />
                  */}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onClose} variant="secondary">
              Close
            </Button>
            <Button type="submit" variant="primary">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </BlockUi>
    </Modal>
  );
}

export default EditPOItemModal;
