import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Button from "react-bootstrap/Button";
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';



function ManageBulkOrderModalAccordion(props) {
    const { rowData, setSelectedMemoNum, setShowSecondModal } = props;
    const [data, setData] = useState([]);
    const [headerData] = useState(["Supplier Name", "Product Name", "Quantity"]);
    const ROWS_PER_PAGE = 10;
    const totalPages = Math.ceil(data.length / ROWS_PER_PAGE);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        setData(rowData);
    }, [rowData])

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleEditButton = (row) => {
        setSelectedMemoNum(row.MEMO_NUMBER);
        setShowSecondModal("edit");
    }

    const renderTableData = (lineData) => {
        const startIndex = (currentPage - 1) * ROWS_PER_PAGE;
        const endIndex = startIndex + ROWS_PER_PAGE;
        return lineData.slice(startIndex, endIndex).map((row) => {
            const { ITEM_ID, PRODUCT_ID, SUPPLIER_NAME, PRODUCT_NAME, ITEM_QTY, TOTAL_MEMO_QTY } = row;
            return (
                <tr key={ITEM_ID}>
                    {/* <td>
                        <Form.Check
                            type="checkbox"
                            onChange={() => toggleRowSelection(row.PRODUCT_ID)}
                            checked={selectedRows.includes(row.PRODUCT_ID)}
                        />
                    </td> */}
                    <td>{SUPPLIER_NAME}</td>
                    <td>{PRODUCT_NAME}</td>
                    <td>{ITEM_QTY}</td>
                </tr>
            );
        });
    };

    const renderPageItems = () => {
        const pageItems = [];
        for (let i = 1; i <= totalPages; i++) {
            pageItems.push(
                <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
                    {i}
                </Pagination.Item>
            );
        }
        return pageItems;
    };
    if (data.length < 1) {
        return (
            <div className="text-center"><h3>No Data</h3></div>
        )
    } else {
        return (
            <>
                {data.map((item, index) => (
                    <Row key={index} className="mb-2">
                        <Col md="10">
                            <Accordion alwaysOpen>
                                <Accordion.Item eventKey={index.toString()}>
                                    <Accordion.Header>{item[0].MEMO_NUMBER + " | " + item[0].SO_DATE}</Accordion.Header>
                                    <Accordion.Body>
                                        <Table striped bordered hover className="mb-4">
                                            <thead>
                                                <tr>
                                                    {headerData.map((header) => (
                                                        <th key={header}>{header}</th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>{renderTableData(item)}</tbody>
                                        </Table>
                                        <Pagination className="d-flex justify-content-center" >{renderPageItems()}</Pagination>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <Col md="2" className='d-flex justify-content-center align-items-center'>
                            <Button onClick={() => handleEditButton(item[0])} variant="primary">
                                Edit
                            </Button>
                        </Col>
                    </Row>
                ))}
            </>
        );
    }
}

export default ManageBulkOrderModalAccordion;