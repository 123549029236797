import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BlockUi from '@availity/block-ui';

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function DeleteSupplierModal(props) {
    const { rowData, onClose, handleShowAlert, handleDeleteItemList } = props;
    const [ItemId] = useState(rowData.ITEM_ID);

    const handleDeleteItem = (event) => {
        event.preventDefault();
        var objData = {
            ITEM_ID: ItemId
        };
        console.log(objData);
        handleDeleteItemList(objData);
        onClose();
    };

    return (
        <Modal show={true} onHide={onClose} size="md" backdrop="static" centered style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
            <Form onSubmit={handleDeleteItem}>
                <Modal.Header closeButton className="px-4">
                    <Modal.Title className="ms-auto">Delete Supplier</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="mb-2">
                        <Col md="12">
                            <div className='text-center'>Do you confirm to delete the supplier?</div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" variant="primary">
                        Yes
                    </Button>
                    <Button onClick={onClose} variant="secondary">
                        No
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

export default DeleteSupplierModal;