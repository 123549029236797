import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import ViewModalTable from "./ManagePO_ViewModalTable";
// import ManageBulkOrderMemoHistoryModalAccordion from "./ManageBulkOrderMemo_HistoryModalAccordion";
// import ManageBulkOrderMemoHistoryEditModal from "./ManageBulkOrderMemo_EditModal";

import { useNavigate } from "react-router-dom";
import {
  clearUserNameAndTokenInLocalStorage,
  getUserNameFromLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
import BlockUi from "@availity/block-ui";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function ViewModal(props) {
  const { rowData, onClose, handleShowAlert, handleCloseAlert } = props;
  const [SleSoNum] = useState(rowData.SO_NUMBER);
  const [SleSoStatus] = useState(rowData.SO_STATUS);
  const [SlePoNum, setSlePoNum] = useState(rowData.PO_NUMBER);
  const [SleMemoNum, setSleMemoNum] = useState("");
  const [SleDoNum, setSleDoNum] = useState("");
  const [CustomerId] = useState(rowData.CUSTOMER_ID);
  const [CustomerName, setCustomerName] = useState(rowData.CUSTOMER_NAME);
  const [CustomerRef, setCustomerRef] = useState(
    rowData.CUSTOMER_REF === null ||
      rowData.CUSTOMER_REF === undefined ||
      rowData.CUSTOMER_REF === ""
      ? "-"
      : rowData.CUSTOMER_REF
  );
  const [ProjectId] = useState(rowData.PROJECT_ID);
  const [ProjectName, setProjectName] = useState(rowData.PROJECT_NAME);
  const [Currency, setCurrency] = useState(rowData.CURRENCY_CODE);
  const [PaymentTerm] = useState(rowData.PAYMENT_TERM);
  const [SupplierName] = useState(rowData.SUPPLIER_NAME);
  const [ExpectedDelivery, setExpectedDelivery] = useState(new Date());
  const [Remarks, setRemarks] = useState(rowData.REMARKS);
  //   const [SleSoDate] = useState(new Date(rowData.SO_DATE));
  //   const [SlePoDate, setSlePoDate] = useState(new Date(rowData.PO_DATE));
  const [SleSoDate] = useState(new Date());
  const [SlePoDate, setSlePoDate] = useState(new Date());
  const [SleMemoDate, setSleMemoDate] = useState(new Date());
  const [PoDate, setPoDate] = useState(new Date());
  const [isIncludeSleDo, setIsIncludeSleDo] = useState(false);
  const [OrderType] = useState(rowData.SO_TYPE_DESC);
  const [POType, setPOType] = useState("");
  const [Trigger, setTrigger] = useState("");
  const [blocking, setBlocking] = useState(true);
  const [textInputs, setTextInputs] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [Data, setData] = useState([]);
  const [ProcessedData, setProcessedData] = useState([]);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [selectedMemoNum, setSelectedMemoNum] = useState("");
  const [ItemList, setItemList] = useState("");
  const [RefreshHistoryTrigger, setRefreshHistoryTrigger] = useState("");
  const [DeliveryTo, setDeliveryTo] = useState("");
  console.log(showSecondModal);
  const navigate = useNavigate();

  const handleIncludeSleDoCheckbox = (event) => {
    setIsIncludeSleDo(event.target.checked);
  };
  const handleToggleBlocking = () => {
    setBlocking((prevState) => !prevState);
  };

  const handleToggleFromChild = (newValue) => {
    setBlocking(newValue);
  };

  const handleToggleClick = (rowData) => {
    setSelectedRowData(rowData);
    setShowSecondModal("toggle");
  };

  useEffect(() => {
    getData(config.po_get_link, rowData);
  }, [rowData]);

  useEffect(() => {
    // Create an object to store grouped data
    const groupedData = Data.reduce((result, item) => {
      const memoNumber = item.MEMO_NUMBER;

      // If the memoNumber is not in the result, create a new array for it
      if (!result[memoNumber]) {
        result[memoNumber] = [];
      }

      // Push the item into the array under the memoNumber key
      result[memoNumber].push(item);

      return result;
    }, {});

    // Convert the grouped data into an array
    const groupedArray = Object.values(groupedData);
    setProcessedData(groupedArray);
  }, [Data]);

  const getData = async (url, params = {}) => {
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify({
        po_number: params.PO_NUMBER,
      }),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        console.log(jsonData);
        setBlocking(false);
        if (
          jsonData.success !== true &&
          jsonData.message === "Access Denied! Invalid token"
        ) {
          clearUserNameAndTokenInLocalStorage();
          throw new Error(jsonData.message);
        } else if (jsonData.success) {
          const newData = jsonData.data;
          newData.PO_DATE = moment(jsonData.data.SO_DATE)
            .utcOffset(480)
            .format("DD MMM yyyy");
          newData.EXPECTED_DELIVERY_DATE = moment(
            jsonData.data.EXPECTED_DELIVERY_DATE
          )
            .utcOffset(480)
            .format("DD MMM yyyy");
          return newData;
        } else {
          setItemList([]);
          throw new Error(jsonData.message);
        }
      })
      .then((data) => {
        setItemList(data.PO_ITEMS);
        setExpectedDelivery(data.EXPECTED_DELIVERY_DATE);
        setSlePoDate(data.PO_DATE);
        setPOType(data.PO_TYPE);
        setRemarks(data.REMARKS === null || data.REMARKS === undefined ? "" : data.REMARKS);
        setDeliveryTo(data.DELIVERY_TO === null || data.DELIVERY_TO === undefined ? "" : data.DELIVERY_TO);
      })
      .catch((error) => console.error(error));

    //   .then((response) => response.json())
    //   .then((jsonData) => {
    //     console.log(jsonData);
    //     setBlocking(false);
    //     if (jsonData.message === "Access Denied! Invalid token") {
    //       clearUserNameAndTokenInLocalStorage();
    //       throw new Error(jsonData.message);
    //     }
    //     const newData = jsonData.data.SO_ITEMS.map((item) => {
    //       const date = moment(item.SO_DATE).format("DD MMM yyyy");
    //       item.SO_DATE = date;
    //       return item;
    //     });
    //     return newData;
    //   })
    //   .then((data) => setData(data))
    //   .catch((error) => console.error(error));
  };

  return (
    <Modal show={true} onHide={onClose} size="xl" backdrop="static" centered>
      <BlockUi blocking={blocking}>
        <Form>
          <Modal.Header closeButton className="px-4">
            <Modal.Title className="ms-auto">View Purchase Order</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={12}>
                <Row className="mb-2">
                  <Col md="6">
                    <Form.Group
                      as={Row}
                      controlId="SLESONumber"
                      className="justify-content-md-center"
                    >
                      <Form.Label
                        column
                        sm="4"
                        className="fw-bold"
                        // style={{ paddingLeft: "100px" }}
                      >
                        PO Number :
                      </Form.Label>
                      <Col sm="6">
                        <Form.Control
                          readOnly
                          className="no-border"
                          type="text"
                          placeholder=""
                          value={SlePoNum}
                        />
                      </Col>
                    </Form.Group>
                  </Col>

                  <Col md="6">
                    <Form.Group
                      as={Row}
                      controlId="SLESONumber"
                      className="justify-content-md-center"
                    >
                      <Form.Label
                        column
                        sm="4"
                        className="fw-bold"
                        // style={{ paddingLeft: "100px" }}
                      >
                        PO Date :
                      </Form.Label>
                      <Col sm="6">
                        <DatePicker
                          readOnly
                          dateFormat="dd/MM/yyyy"
                          className="form-control no-border"
                          showIcon
                          selected={new Date((new Date(SlePoDate) * 1) + (480*60*1000))}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md="6">
                    <Form.Group
                      as={Row}
                      controlId="SLESONumber"
                      className="justify-content-md-center"
                    >
                      <Form.Label
                        column
                        sm="4"
                        className="fw-bold"
                        // style={{ paddingLeft: "100px" }}
                      >
                        PO Type :
                      </Form.Label>
                      <Col sm="6">
                        <Form.Control
                          readOnly
                          className="no-border"
                          type="text"
                          placeholder=""
                          value={POType}
                        />
                      </Col>
                    </Form.Group>
                  </Col>

                  <Col md="6">
                    <Form.Group
                      as={Row}
                      controlId="SLESONumber"
                      className="justify-content-md-center"
                    >
                      <Form.Label
                        column
                        sm="4"
                        className="fw-bold"
                        // style={{ paddingLeft: "100px" }}
                      >
                        Expected Delivery :
                      </Form.Label>
                      <Col sm="6">
                        <DatePicker
                          readOnly
                          dateFormat="dd/MM/yyyy"
                          className="form-control no-border"
                          showIcon
                          selected={new Date((new Date(ExpectedDelivery) * 1) + (480*60*1000))}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md="12">
                    <Form.Group
                      as={Row}
                      controlId="SLESONumber"
                      className="justify-content-md-center"
                    >
                      <Form.Label
                        column
                        sm="2"
                        className="fw-bold"
                        // style={{ paddingLeft: "100px" }}
                      >
                        Supplier Name :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          readOnly
                          className="no-border"
                          type="text"
                          placeholder=""
                          value={SupplierName}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md="6">
                    <Form.Group
                      as={Row}
                      controlId="SLESONumber"
                      className="justify-content-md-center"
                    >
                      <Form.Label
                        column
                        sm="4"
                        className="fw-bold"
                        // style={{ paddingLeft: "100px" }}
                      >
                        Currency :
                      </Form.Label>
                      <Col sm="6">
                        <Form.Control
                          readOnly
                          className="no-border"
                          type="text"
                          placeholder=""
                          value={Currency}
                        />
                      </Col>
                    </Form.Group>
                  </Col>

                  <Col md="6">
                    <Form.Group
                      as={Row}
                      controlId="SLESONumber"
                      className="justify-content-md-center"
                    >
                      <Form.Label
                        column
                        sm="4"
                        className="fw-bold"
                        // style={{ paddingLeft: "100px" }}
                      >
                        Delivery To :
                      </Form.Label>
                      <Col sm="6">
                      <Form.Control
                          readOnly
                          className="no-border"
                          type="text"
                          placeholder=""
                          value={DeliveryTo === "C" ? "Client's Premise" : DeliveryTo === "W" ?  "Warehouse" : ""}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md="6">
                    <Form.Group
                      as={Row}
                      controlId="SLESONumber"
                      className="justify-content-md-center"
                    >
                      <Form.Label
                        column
                        sm="4"
                        className="fw-bold"
                        // style={{ paddingLeft: "100px" }}
                      >
                        SO Number :
                      </Form.Label>
                      <Col sm="6">
                        <Form.Control
                          readOnly
                          className="no-border"
                          type="text"
                          placeholder=""
                          value={SleSoNum}
                        />
                      </Col>
                    </Form.Group>
                  </Col>

                  <Col md="6">
                    <Form.Group
                      as={Row}
                      controlId="SLESONumber"
                      className="justify-content-md-center"
                    >
                      <Form.Label
                        column
                        sm="4"
                        className="fw-bold"
                        // style={{ paddingLeft: "100px" }}
                      >
                        SO Type :
                      </Form.Label>
                      <Col sm="6">
                        <Form.Control
                          readOnly
                          className="no-border"
                          type="text"
                          placeholder=""
                          value={OrderType}
                          // style={{ paddingLeft: "25px" }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md="6">
                    <Form.Group
                      as={Row}
                      controlId="SLESONumber"
                      className="justify-content-md-center"
                    >
                      <Form.Label
                        column
                        sm="4"
                        className="fw-bold"
                        // style={{ paddingLeft: "100px" }}
                      >
                        Customer Name :
                      </Form.Label>
                      <Col sm="6">
                        <Form.Control
                          readOnly
                          className="no-border"
                          type="text"
                          placeholder=""
                          value={CustomerName}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md="6">
                    <Form.Group
                      as={Row}
                      controlId="SLESONumber"
                      className="justify-content-md-center"
                    >
                      <Form.Label
                        column
                        sm="4"
                        className="fw-bold"
                        // style={{ paddingLeft: "100px" }}
                      >
                        Project Name :
                      </Form.Label>
                      <Col sm="6">
                        <Form.Control
                          readOnly
                          className="no-border"
                          type="text"
                          placeholder=""
                          value={ProjectName}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mb-2">
                  <Col md="12">
                    <Form.Group
                      as={Row}
                      controlId="Remarks"
                      className="justify-content-md-center"
                    >
                      <Form.Label
                        column
                        sm="2"
                        className="fw-bold"
                        // style={{ paddingLeft: "60px" }}
                      >
                        Remarks :
                      </Form.Label>
                      <Col sm="9" style={{ paddingRight: "50px" }}>
                        <Form.Control
                          readOnly
                          as="textarea"
                          rows={5}
                          placeholder="-"
                          value={Remarks}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* <Row className="mb-2 justify-content-center">
              <Col md="6">
                <Form.Group as={Row} controlId="SLESONumber">
                  <Form.Label column sm="6" className="fw-bold">
                    SLE PO Number :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={SlePoNum}
                    />
                  </Col>
                </Form.Group>
              </Col>

              <Col md="6">
                <Form.Group as={Row} controlId="SLESODate">
                  <Form.Label column sm="6" className="fw-bold">
                    SLE PO Date :
                  </Form.Label>
                  <Col sm="6">
                    <DatePicker
                      readOnly
                      dateFormat="dd/MM/yyyy"
                      className="form-control no-border"
                      showIcon
                      selected={SlePoDate}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row> */}
            <hr />
            <ViewModalTable
              ItemList={ItemList}
              OrderType={OrderType}
              //   setSelectedMemoNum={setSelectedMemoNum}
              //   setShowSecondModal={setShowSecondModal}
              // setSelectedRowData = {setSelectedRowData}
              // onChildToggleChange={handleToggleFromChild}
              // onRowSelect={toggleRowSelection}
              // onInputChange={handleInputChange}
              // selectedRows={selectedRows}
              // setSelectedRows={setSelectedRows}
              // textInputs={textInputs}
              // setTextInputs={setTextInputs}
              // toggleRowSelection={toggleRowSelection}
            />
            {/* {showSecondModal === "edit" ? (
              <ManageBulkOrderMemoHistoryEditModal
                handleCloseAlert={handleCloseAlert}
                handleShowAlert={handleShowAlert}
                rowData={rowData}
                selectedMemoNum={selectedMemoNum}
                onClose={() => setShowSecondModal(false)}
                setTrigger={setTrigger}
                setRefreshHistoryTrigger={setRefreshHistoryTrigger}
              />
            ) : (
              ""
            )} */}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onClose} variant="secondary">
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </BlockUi>
    </Modal>
  );
}

export default ViewModal;
