import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";
import { clearUserNameAndTokenInLocalStorage, getUserNameFromLocalStorage, getTokenFromLocalStorage } from '../../lib/common';
import BlockUi from '@availity/block-ui';

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function NewDeliveryOrderCreateConfirmationModal(props) {
    const { rowData, onClose, handleShowAlert, selectedRows, setRefreshTrigger, selectedTable, scheduleDate, setShowNewNonBulkOrder } = props;
    const [blocking, setBlocking] = useState(false);

    const handleToggleBlocking = () => {
        setBlocking(prevState => !prevState);
    };
    
    const handleCreateNewDeliveryOrder = async (event) => {
        event.preventDefault();
        if (rowData.SO_TYPE !== "B"){
            onClose();
            setShowNewNonBulkOrder(true);
        } else {
            return;
        }
        try {
            handleToggleBlocking();
            console.log("in confirmation")
            console.log(rowData);
            console.log(selectedTable);
            await createNewDeliveryOrder(config.do_create_link);
           
            onClose();
            handleShowAlert("ok", "Create New Delivery Order", "Success creating New Delivery Order");
        } catch (error) {
            handleToggleBlocking();
            console.error("Error while creating New Delivery Order :", error);
            handleShowAlert("error", "Create New Delivery Order", "Error while creating New Delivery Order: ", error.join("<br>"));
            return;
        }

    };

    const createNewDeliveryOrder = async (url) => {
        try {
            const memo_item_id = [];
            const product_id = [];
            const item_qty = [];
            for (var i in selectedTable){
                memo_item_id.push(selectedTable[i].ITEM_ID);
                product_id.push(selectedTable[i].PRODUCT_ID);
                item_qty.push(selectedTable[i].ITEM_QTY);
            }
            console.log()
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + getTokenFromLocalStorage()
                },
                body: JSON.stringify({
                    so_number : rowData.SO_NUMBER,
                    po_number : rowData.PO_NUMBER !== undefined ? rowData.PO_NUMBER : "",
                    memo_number : selectedTable[0].MEMO_NUMBER,
                    schedule_date : moment(scheduleDate).format("YYYY-MM-DD"),
                    instructions : "",
                    memo_item_id : memo_item_id,
                    product_id : product_id,
                    item_qty : item_qty
                })
            });
            const data = await response.json();
            setRefreshTrigger(moment().unix());
            console.log("create new delivery order response : ");
            console.log(data);
            if (
                data.success !== true &&
                data.message === "Access Denied! Invalid token"
            ) {
                clearUserNameAndTokenInLocalStorage();
            }

            if (data.success !== true) {
                var errorMessages = data.error.map((item) => item.msg);
                // handleShowAlert("error", "New Invoice", "Failed Saving New Invoice :", errorMessages.join("<br>"));
                throw errorMessages;
            }
            if (data.success === true) {
                console.log("Success Creating New Delivery Order");
                // handleTrigger(moment().unix());
                return "ok";
            } else {
                throw data;
            }
        } catch (error) {
            console.error("Error while creating New Delivery Order:", error);
            throw error;
        }
    }



    return (
        <Modal show={true} onHide={onClose} size="md" backdrop="static" style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }} centered>
            <BlockUi blocking={blocking}>
                <Form>
                    <Modal.Header closeButton className="px-4">
                        <Modal.Title className="ms-auto">Create New Delivery Order</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="mb-2">
                            <Col md="12">
                                <div className='text-center'>Do you confirm to create a new delivery order with the selected MEMO ?</div>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={handleCreateNewDeliveryOrder} type="submit" variant="primary">
                            Yes
                        </Button>
                        <Button onClick={onClose} variant="secondary">
                            No
                        </Button>
                    </Modal.Footer>
                </Form>
            </BlockUi>
        </Modal>
    );
}

export default NewDeliveryOrderCreateConfirmationModal;