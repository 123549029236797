import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";
import {
  clearUserNameAndTokenInLocalStorage,
  getUserNameFromLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
import BlockUi from "@availity/block-ui";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function SearchDeliveryOrderDeleteConfirmationModal(props) {
  const { rowData, onClose, handleShowAlert, selectedRows, setRefreshTrigger } =
    props;
  const [DoNumber] = useState(rowData.DO_NUMBER);
  const [blocking, setBlocking] = useState(false);

  const handleToggleBlocking = () => {
    setBlocking((prevState) => !prevState);
  };

  const handleCancelDeliveryOrder = async (event) => {
    event.preventDefault();
    try {
      handleToggleBlocking();
      await cancelDeliveryOrder();
      console.log("Success cancelling ");
      onClose();
      handleShowAlert(
        "ok",
        "Cancel Delivery Order",
        "Success cancelling Delivery Order"
      );
    } catch (error) {
      handleToggleBlocking();
      console.error("Error while cancelling Delivery Order :", error);
      handleShowAlert(
        "error",
        "Cancel Delivery Order",
        "Error while Delivery Order: ",
        error.join("<br>")
      );
      return;
    }
  };

  const cancelDeliveryOrder = async () => {
    try {
      const response = await fetch(config.do_cancel_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getTokenFromLocalStorage(),
        },
        body: JSON.stringify({
          do_number: rowData.DO_NUMBER,
        }),
      });
      const data = await response.json();
      console.log("cancel do response : ");
      console.log(data);
      if (
        data.success !== true &&
        data.message === "Access Denied! Invalid token"
      ) {
        clearUserNameAndTokenInLocalStorage();
      }

      if (data.success !== true) {
        var errorMessages = data.error.map((item) => item.msg);
        // handleShowAlert("error", "New Invoice", "Failed Saving New Invoice :", errorMessages.join("<br>"));
        throw errorMessages;
      }
      if (data.success === true) {
        console.log("Success Cancelling DO");
        // handleTrigger(moment().unix());
        return "ok";
      } else {
        throw data;
      }
    } catch (error) {
      console.error("Error while Cancelling DO:", error);
      throw error;
    }
  };

  return (
    <Modal
      show={true}
      onHide={onClose}
      size="md" backdrop="static"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      centered
    >
      <BlockUi blocking={blocking}>
        <Form>
          <Modal.Header closeButton className="px-4">
            <Modal.Title className="ms-auto">Cancel Delivery Order</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-2">
              <Col md="12">
                <div className="text-center">
                  Do you confirm to cancel the selected Delivery Order ?
                </div>
                <div className='text-center fw-bold'>{DoNumber}</div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={handleCancelDeliveryOrder}
              type="submit"
              variant="primary"
            >
              Yes
            </Button>
            <Button onClick={onClose} variant="secondary">
              No
            </Button>
          </Modal.Footer>
        </Form>
      </BlockUi>
    </Modal>
  );
}

export default SearchDeliveryOrderDeleteConfirmationModal;
