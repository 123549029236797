import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import {
  clearUserNameAndTokenInLocalStorage,
  getUserNameFromLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
import BlockUi from "@availity/block-ui";
import { useForm, Controller } from "react-hook-form";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function DeliveryStatusStatusModal(props) {
  const { rowData, onClose, handleShowAlert } = props;
  const [SleSoNum] = useState(rowData.SO_NUMBER);
  // const [SlePoNum] = useState(rowData.PO_NUMBER);
  const [SleSoDate] = useState(new Date(rowData.SO_DATE));
  // const [SlePoDate] = useState(rowData.PO_DATE === null || rowData.PO_DATE === undefined ? "" : new Date(rowData.PO_DATE));
  const [SleDoNum, setSleDoNum] = useState(
    rowData.DO_NUMBER ? rowData.DO_NUMBER : ""
  );
  const [SleDoDate, setSleDoDate] = useState(
    rowData.DO_DATE ? new Date(rowData.DO_DATE) : ""
  );
  const [PaymentTerm] = useState(
    rowData.PAYMENT_TERM === undefined ? "" : rowData.PAYMENT_TERM
  );
  const [CustomerName] = useState(rowData.CUSTOMER_NAME);
  const [ProjectName] = useState(rowData.PROJECT_NAME);
  // const [SleSoDate] = useState("");
  // const [SlePoDate] = useState("");
  // const [SleDoNum, setSleDoNum] = useState("");
  // const [SleDoDate, setSleDoDate] = useState("");
  const [OrderType] = useState(rowData.SO_TYPE_DESC);
  const [ActualDeliveryDate, setActualDeliveryDate] = useState("");
  const [SupplierDoNum, setSupplierDoNum] = useState("");
  const [SupplierDoDate, setSupplierDoDate] = useState("");
  const [SleGRNNum, setSleGRNNum] = useState("");
  const [SleGRNDate, setSleGRNDate] = useState(new Date());
  const [blocking, setBlocking] = useState(false);
  const [minDate] = useState(new Date(rowData.DO_DATE));
  const [maxDate] = useState(new Date());

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ActualDeliveryDate: "",
      SupplierDoDate: "",
    },
  });

  const handleSaveStatus = async (event) => {
    // event.preventDefault();
    try {
      handleToggleBlocking();

      // if (rowData.DO_NUMBER === null) {
      //   await submitDO();
      // }
      // await submitGRN();
      await submitDeliveryStatus();
      handleToggleBlocking();
      onClose();
      handleShowAlert(
        "ok",
        "Update Delivery Status",
        "Success saving Delivery Status"
      );
      console.log("Success saving Delivery Status");
    } catch (error) {
      handleToggleBlocking();
      handleShowAlert(
        "error",
        "Update Delivery Status",
        "Error while saving Delivery Status:",
        error
      );
      console.error(
        "error",
        "Update Delivery Status",
        "Error while saving Delivery Status:",
        error
      );
      return;
    }
  };

  const handleToggleBlocking = () => {
    setBlocking((prevState) => !prevState);
  };

  const submitDO = async () => {
    try {
      const response = await fetch(config.do_create_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getTokenFromLocalStorage(),
        },
        body: JSON.stringify({
          // po_number: SlePoNum,
          do_number: SleDoNum,
          do_date: moment(SleDoDate).format("YYYY-MM-DD"),
          user_modify: getUserNameFromLocalStorage(),
        }),
      });

      const contentType = response.headers.get("content-type");
      const data =
        contentType && contentType.includes("application/json")
          ? await response.json()
          : await response.text();
      console.log(data);

      if (data.message === "Access Denied! Invalid token") {
        clearUserNameAndTokenInLocalStorage();
        throw data.message;
      } else if (data.message) {
        throw data.message;
      }
      if (data.errors) {
        var errorMessages = data.errors.map((item) => item.msg);
        throw errorMessages.join("<br>");
      }
      if (data.affectedRows > 0) {
        console.log("Success Submitting DO");
        return "ok";
      }
    } catch (error) {
      console.error("Error while submitting DO:", error);
      throw error;
    }
  };

  const submitDeliveryStatus = async () => {
    try {
      const response = await fetch(config.deliverystatus_create_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getTokenFromLocalStorage(),
        },
        body: JSON.stringify({
          do_number: SleDoNum,
          // po_number: SlePoNum,
          so_number: SleSoNum,
          actual_delivery: moment(getValues("ActualDeliveryDate")).format("YYYY-MM-DD"),
          supplier_do_number: SupplierDoNum,
          supplier_do_date: SupplierDoDate ? moment(SupplierDoDate).format("YYYY-MM-DD") : "",
          user_modify: getUserNameFromLocalStorage(),
        }),
      });

      const data = await response.json();
      console.log("submit update delivery status response : ");
      console.log(data);
      if (
        data.success !== true &&
        data.message === "Access Denied! Invalid token"
      ) {
        clearUserNameAndTokenInLocalStorage();
      }

      if (data.success !== true) {
        var errorMessages = data.error.map((item) => item.msg);
        // handleShowAlert("error", "New Invoice", "Failed Saving New Invoice :", errorMessages.join("<br>"));
        throw errorMessages;
      }
      if (data.success === true) {
        console.log("Success Updating Delivery Status");
        // handleTrigger(moment().unix());
        return "ok";
      } else {
        throw data;
      }
    } catch (error) {
      console.error("Error while updating Delivery Status:", error);
      throw error;
    }
  };

  const submitGRN = async () => {
    try {
      const response = await fetch(config.grn_create_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getTokenFromLocalStorage(),
        },
        body: JSON.stringify({
          grn_number: SleGRNNum,
          grn_date: moment(SleGRNDate).format("YYYY-MM-DD"),
          user_modify: getUserNameFromLocalStorage(),
        }),
      });

      const contentType = response.headers.get("content-type");
      const data =
        contentType && contentType.includes("application/json")
          ? await response.json()
          : await response.text();
      console.log(data);
      if (data.message === "Access Denied! Invalid token") {
        clearUserNameAndTokenInLocalStorage();
        throw data.message;
      } else if (data.message) {
        throw data.message;
      }
      if (data.errors) {
        var errorMessages = data.errors.map((item) => item.msg);
        throw errorMessages.join("<br>");
      }
      if (data.affectedRows > 0) {
        console.log("Success Submitting GRN");
        return "ok";
      } else {
        throw new Error("Failed Submitting GRN!");
      }
    } catch (error) {
      console.error("Error while submitting GRN:", error);
      throw error;
    }
  };

  return (
    <Modal show={true} onHide={onClose} size="lg" backdrop="static" centered>
      <BlockUi blocking={blocking}>
        <Form onSubmit={handleSubmit(handleSaveStatus)}>
          <Modal.Header closeButton className="px-4">
            <Modal.Title className="ms-auto">
              Update Delivery Status
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-2 justify-content-center">
              <Col md="6">
                <Form.Group as={Row} controlId="SLESONumber">
                  <Form.Label column sm="6" className="fw-bold">
                    SLE SO Number :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={SleSoNum}
                    />
                  </Col>
                </Form.Group>
              </Col>

              <Col md="6">
                <Form.Group as={Row} controlId="SLESODate">
                  <Form.Label column sm="6" className="fw-bold">
                    SLE SO Date :
                  </Form.Label>
                  <Col sm="6">
                    <DatePicker
                      readOnly
                      dateFormat="dd/MM/yyyy"
                      className="form-control no-border"
                      showIcon
                      selected={SleSoDate}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md="6">
                <Form.Group as={Row} controlId="OrderType">
                  <Form.Label column sm="6" className="fw-bold">
                    Order Type :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={OrderType}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group as={Row} controlId="OrderType">
                  <Form.Label column sm="6" className="fw-bold">
                    Payment Term :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={PaymentTerm}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            {/* <Row className="mb-2 justify-content-center">
              <Col md="6">
                <Form.Group as={Row} controlId="SLEPONumber">
                  <Form.Label column sm="6" className="fw-bold">
                    SLE PO Number :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control readOnly className="no-border" type="text" placeholder="" value={SlePoNum} />
                  </Col>
                </Form.Group>
              </Col>

              <Col md="6">
                <Form.Group as={Row} controlId="SLEPODate">
                  <Form.Label column sm="6" className="fw-bold">
                    SLE PO Date :
                  </Form.Label>
                  <Col sm="6">
                    <DatePicker readOnly dateFormat="dd/MM/yyyy" className="form-control no-border" showIcon selected={SlePoDate} />
                  </Col>
                </Form.Group>
              </Col>
            </Row> */}
            {rowData.DO_NUMBER !== null ? (
              <>
                <Row className="mb-2 justify-content-center">
                  <Col md="6">
                    <Form.Group as={Row} controlId="SLEDONumber">
                      <Form.Label column sm="6" className="fw-bold">
                        SLE DO Number :
                      </Form.Label>
                      <Col sm="6">
                        <Form.Control
                          readOnly
                          className="no-border"
                          type="text"
                          placeholder=""
                          value={SleDoNum}
                        />
                      </Col>
                    </Form.Group>
                  </Col>

                  <Col md="6">
                    <Form.Group as={Row} controlId="SLEDODate">
                      <Form.Label column sm="6" className="fw-bold">
                        SLE DO Date :
                      </Form.Label>
                      <Col sm="6">
                        <DatePicker
                          readOnly
                          dateFormat="dd/MM/yyyy"
                          className="form-control no-border"
                          showIcon
                          selected={SleDoDate}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </>
            ) : (
              ""
            )}
            <Row className="mb-2">
              <Col md="9">
                <Form.Group as={Row} controlId="SLESONumber">
                  <Form.Label column sm="4" className="fw-bold">
                    Customer Name :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={CustomerName}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md="9">
                <Form.Group as={Row} controlId="SLESONumber">
                  <Form.Label column sm="4" className="fw-bold">
                    Project Name :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={ProjectName}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <hr />
            <Row className="mb-2">
              <Col md="6">
                <Form.Group as={Row} controlId="ActualDeliveryDate">
                  <Form.Label column sm="6" className="fw-bold">
                    Actual Delivery Date :
                  </Form.Label>
                  <Col sm="6">
                  <Controller
                        name="ActualDeliveryDate"
                        control={control}
                        rules={{
                          required: "This field is required",
                          pattern: {
                            value:
                              /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/,
                            message: "Invalid date format",
                          },
                        }}
                        render={({ field }) => (
                          <>
                            <DatePicker
                              selected={field.value}
                              onChange={(date) => field.onChange(date)}
                              className={`form-control ${
                                errors.ActualDeliveryDate ? "is-invalid" : ""
                              }`}
                              dateFormat="dd/MM/yyyy"
                              minDate={minDate}
                              maxDate={maxDate}
                            />
                            {errors.ActualDeliveryDate && (
                              <Form.Text className="text-danger">
                                {errors.ActualDeliveryDate.message}
                              </Form.Text>
                            )}
                          </>
                        )}
                      />
                    {/* <DatePicker
                      dateFormat="dd/MM/yyyy"
                      className="form-control"
                      showIcon
                      selected={ActualDeliveryDate}
                      onChange={(date) => setActualDeliveryDate(date)}
                      minDate={minDate}
                      maxDate={maxDate}
                    /> */}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md="6">
                <Form.Group as={Row} controlId="SupplierDONumber">
                  <Form.Label column sm="6" className="fw-bold">
                    Supplier DO Number :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      type="text"
                      placeholder=""
                      value={SupplierDoNum}
                      onInput={(e) => setSupplierDoNum(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group as={Row} controlId="SupplierDODate">
                  <Form.Label column sm="6" className="fw-bold">
                    Supplier DO Date :
                  </Form.Label>
                  <Col sm="6">
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      className="form-control"
                      showIcon
                      selected={SupplierDoDate}
                      onChange={(date) => setSupplierDoDate(date)}
                      minDate={minDate}
                      maxDate={maxDate}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2"></Row>
            {rowData.DO_NUMBER === null ? (
              <>
                <Row className="mb-2">
                  <Col md="6">
                    <Form.Group as={Row} controlId="SLEDONumber">
                      <Form.Label column sm="6" className="fw-bold">
                        SLE DO Number :
                      </Form.Label>
                      <Col sm="6">
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={SleDoNum}
                          onInput={(e) => setSleDoNum(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md="6">
                    <Form.Group as={Row} controlId="SLEDODate">
                      <Form.Label column sm="6" className="fw-bold">
                        SLE DO Date :
                      </Form.Label>
                      <Col sm="6">
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          className="form-control"
                          showIcon
                          selected={SleDoDate}
                          onChange={(date) => setSleDoDate(date)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </>
            ) : (
              ""
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onClose} variant="secondary">
              Close
            </Button>
            <Button
              type="submit"
              variant="primary"
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </BlockUi>
    </Modal>
  );
}

export default DeliveryStatusStatusModal;
