import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Pagination from "react-bootstrap/Pagination";
import moment from "moment/moment";
import {
  clearUserNameAndTokenInLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
const config = JSON.parse(process.env.REACT_APP_CONFIG);

function ManageSoTable(props) {
  const [data, setData] = useState([]);
  const [headerData] = useState([
    "SO Number",
    "SO Date",
    "Order Type",
    "Customer Name",
    "Project Name",
    "Customer Ref",
    "Order Status",
    "Restock Status",
    "Action",
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const { onOnHoldClick } = props;
  const { onRestockClick } = props;
  const { onCancelClick } = props;
  const { onViewClick } = props;
  const { onEditClick } = props;
  const { searchProps } = props;
  const { showAlert } = props;
  const ROWS_PER_PAGE = 10;
  const totalPages = Math.ceil(data.length / ROWS_PER_PAGE);

  useEffect(() => {
    if (
      searchProps.SleSoNum ||
      searchProps.SleSoDateFrom ||
      searchProps.SleSoDateTo ||
      searchProps.CustomerName ||
      searchProps.ProjectName ||
      searchProps.RestockStatus ||
      searchProps.SoType
    ) {
      getData(config.so_list_link, searchProps);
    } else {
      getData(config.so_list_link);
    }
  }, [searchProps, showAlert]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleToggleBlocking = () => {
    props.onChildToggleChange(false);
  };

  const renderTableData = () => {
    const startIndex = (currentPage - 1) * ROWS_PER_PAGE;
    const endIndex = startIndex + ROWS_PER_PAGE;
    return data.slice(startIndex, endIndex).map((row) => {
      const {
        SO_NUMBER,
        SO_DATE,
        SO_TYPE_DESC,
        CUSTOMER_NAME,
        PROJECT_NAME,
        SO_STATUS,
        RESTOCK_STATUS,
        CAN_UPDATE_SO,
        CUSTOMER_REF,
      } = row;
      return (
        <tr key={SO_NUMBER}>
          <td>{SO_NUMBER}</td>
          <td>{SO_DATE}</td>
          <td>{SO_TYPE_DESC}</td>
          <td>{CUSTOMER_NAME}</td>
          <td>{PROJECT_NAME}</td>
          <td>{CUSTOMER_REF}</td>
          <td>
            {SO_STATUS === "V"
              ? "Valid"
              : SO_STATUS === "H"
                ? "Hold"
                : SO_STATUS === "C"
                  ? "Cancel"
                  : ""}
          </td>
          <td>{RESTOCK_STATUS}</td>
          <td style={{ width: "510px" }}>
            <Button
              style={{ marginRight: "5px" }}
              size="sm"
              variant="primary"
              onClick={() => onViewClick(row)}
            >
              View
            </Button>
            <Button
              style={{ marginRight: "5px" }}
              size="sm"
              variant="primary"
              onClick={() => onEditClick(row)}
              disabled={CAN_UPDATE_SO === "N" ? true : false}
            >
              Edit
            </Button>

            <Button
              style={{ marginRight: "5px" }}
              size="sm"
              variant="primary"
              onClick={() => onOnHoldClick(row)}
            >
              On-hold
            </Button>
            <Button
              variant="primary"
              size="sm"
              style={{ marginRight: "5px" }}
              onClick={() => onRestockClick(row)}
              disabled={CAN_UPDATE_SO === "N" ? true : false}
            >
              Restock
            </Button>
            <Button
              size="sm"
              disabled={CAN_UPDATE_SO === "N" ? true : false}
              variant="primary"
              onClick={() => onCancelClick(row)}
            >
              Cancel
            </Button>
          </td>
        </tr>
      );
    });
  };

  const renderPageItems = () => {
    const pageItems = [];
    for (let i = 1; i <= totalPages; i++) {
      pageItems.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }
    return pageItems;
  };

  const getData = async (url, params = {}) => {
    console.log({
      so_number: params.SleSoNum,
      so_date_from: params.SleSoDateFrom,
      so_date_to: params.SleSoDateTo,
      customer_id: params.CustomerName,
      project_id: params.ProjectName,
      pending_restock: params.RestockStatus,
      so_type: params.SoType
    })
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify(
        params.length === 0
          ? params
          : {
            so_number: params.SleSoNum,
            so_date_from: params.SleSoDateFrom,
            so_date_to: params.SleSoDateTo,
            customer_id: params.CustomerName,
            project_id: params.ProjectName,
            pending_restock: params.RestockStatus,
            so_type: params.SoType
          }
      ),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        console.log(jsonData);
        handleToggleBlocking();
        if (jsonData.message === "Access Denied! Invalid token") {
          clearUserNameAndTokenInLocalStorage();
          throw new Error(jsonData.message);
        }
        const newData = jsonData.result.map((item) => {
          const date = moment(item.SO_DATE).utcOffset(480).format("DD MMM yyyy");
          item.SO_DATE = date;
          return item;
        });
        return newData;
      })
      .then((data) => setData(data))
      .catch((error) => console.error(error));
  };

  if (data.length < 1) {
    return (
      <div className="text-center">
        <h3>No Data</h3>
      </div>
    );
  } else {
    return (
      <div>
        <Table striped bordered hover className="mb-4">
          <thead>
            <tr>
              {headerData.map((header) => (
                <th key={header}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>{renderTableData()}</tbody>
        </Table>
        <Pagination className="d-flex justify-content-center">
          {renderPageItems()}
        </Pagination>
      </div>
    );
  }
}

export default ManageSoTable;
