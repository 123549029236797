import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Pagination from "react-bootstrap/Pagination";
import {
  clearUserNameAndTokenInLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
import { v4 as uuidv4 } from "uuid";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function ProductsTable(props) {
  const [data, setData] = useState([]);
  const [headerData] = useState([
    "Brand",
    "Code",
    "Name",
    // "Supplier Name",
    // "Sup Item Code",
    // "Sup Item Name",
    "UOM",
    "Min Sell Price",
    "Status",
    "Action",
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const { onEditClick } = props;
  const { searchProps } = props;
  const { showAlert } = props;
  const { handleShowAlert } = props;
  const ROWS_PER_PAGE = 10;
  const totalPages = Math.ceil(data.length / ROWS_PER_PAGE);


  useEffect(() => {
    console.log(searchProps);
    if (
      searchProps.SupplierId ||
      searchProps.ProductName ||
      searchProps.BrandName ||
      searchProps.Status ||
      searchProps.SupplierItemCode ||
      searchProps.SupplierItemName
    ) {
      getData(config.product_list_link, searchProps);
    } else {
      getData(config.product_list_link);
    }
  }, [searchProps, showAlert]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleToggleBlocking = () => {
    props.onChildToggleChange(false);
  };

  const formatNumberWithCommas = (number) => {
    return parseFloat(number)
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, "$&,");
  };

  const renderTableData = () => {
    const startIndex = (currentPage - 1) * ROWS_PER_PAGE;
    const endIndex = startIndex + ROWS_PER_PAGE;
    return data.slice(startIndex, endIndex).map((row) => {
      const {
        PRODUCT_ID,
        ID,
        BRAND,
        PRODUCT_NAME,
        SUPPLIER_NAME,
        SUPPLIER_ITEM_CODE,
        SUPPLIER_ITEM_NAME,
        UNIT_MEASURE,
        // UNIT_PRICE,
        MIN_SELL_PRICE,
        PRODUCT_STATUS,
      } = row;
      return (
        <tr key={ID}>
          <td>{BRAND}</td>
          <td>{PRODUCT_ID}</td>
          <td>{PRODUCT_NAME}</td>
          <td>{UNIT_MEASURE}</td>
          <td>{MIN_SELL_PRICE}</td>
          {/* <td>{SUPPLIER_NAME !== null ? SUPPLIER_NAME : "-"}</td> */}
          {/* <td>{SUPPLIER_ITEM_CODE !== null ? SUPPLIER_ITEM_CODE : "-"}</td>
          <td>{SUPPLIER_ITEM_NAME !== null ? SUPPLIER_ITEM_NAME : "-"}</td> */}
          <td>{PRODUCT_STATUS === "A" ? "Active" : "Inactive"}</td>
          <td style={{ width: "100px" }}>
            <Button variant="primary" onClick={() => onEditClick(row)}>
              Edit
            </Button>
          </td>
        </tr>
      );
    });
  };

  const renderPageItems = () => {
    const pageItems = [];
    for (let i = 1; i <= totalPages; i++) {
      pageItems.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }
    return pageItems;
  };

  const getData = async (url, params = {}) => {
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify(
        params.length === 0
          ? params
          : {
            supplier_id: params.SupplierId,
            product_name: params.ProductName,
            brand: params.BrandName,
            product_status: params.Status,
            supplier_item_code: params.SupplierItemCode,
            supplier_item_name: params.SupplierItemName
          }
      ),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        console.log(jsonData);
        handleToggleBlocking();
        if (
          jsonData.success !== true &&
          jsonData.message === "Access Denied! Invalid token"
        ) {
          clearUserNameAndTokenInLocalStorage();
          throw (jsonData.message);
        } else if (jsonData.success) {
          return jsonData.data;
        } else {
          throw (jsonData.message);
        }
      })
      .then((data) => setData(addIds(data)))
      .catch((error) => {
        console.error(error);
        // handleShowAlert(
        //   "error",
        //   "Products",
        //   "Failed Getting Product List:",
        //   [error]
        // );
      });
  };

  function addIds(data) {
    return data.map((product) => {
      // Add the custom ID to each object
      var uuid = uuidv4();
      const productWithCustomID = { ...product, ID: uuid };
      return productWithCustomID;
    });
  }

  if (data.length < 1) {
    return (
      <div className="text-center">
        <h3>No Data</h3>
      </div>
    );
  } else {
    return (
      <div>
        <Table striped bordered hover className="mb-4">
          <thead>
            <tr>
              {headerData.map((header) => (
                <th key={header}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>{renderTableData()}</tbody>
        </Table>
        <Pagination className="d-flex justify-content-center">
          {renderPageItems()}
        </Pagination>
      </div>
    );
  }
}

export default ProductsTable;
