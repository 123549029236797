import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import EditModalTable from "./ManageSO_EditModalTable";
import ManageSONewItemModal from "./ManageSO_NewSOItemModal";
import ManageSOEditItemModal from "./ManageSO_EditSOItemModal";
import ManageSODeleteItemModal from "./ManageSO_DeleteSOItemModal";
// import ManageBulkOrderMemoHistoryModalAccordion from "./ManageBulkOrderMemo_HistoryModalAccordion";
// import ManageBulkOrderMemoHistoryEditModal from "./ManageBulkOrderMemo_EditModal";

import { useNavigate } from "react-router-dom";
import {
  clearUserNameAndTokenInLocalStorage,
  getUserNameFromLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
import BlockUi from "@availity/block-ui";
import { useForm, Controller } from "react-hook-form";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function EditModal(props) {
  const { rowData, onClose, handleShowAlert, handleCloseAlert } = props;
  const [SleSoNum] = useState(rowData.SO_NUMBER);
  const [SleSoStatus] = useState(rowData.SO_STATUS);
  const [SlePoNum, setSlePoNum] = useState(rowData.PO_NUMBER);
  const [SleMemoNum, setSleMemoNum] = useState("");
  const [SleDoNum, setSleDoNum] = useState("");
  const [CustomerId, setCustomerId] = useState(rowData.CUSTOMER_ID);
  const [CustomerName, setCustomerName] = useState(rowData.CUSTOMER_NAME);
  const [CustomerList, setCustomerList] = useState([]);
  const [ProjectList, setProjectList] = useState([]);
  const [ProjectId, setProjectId] = useState(
    rowData.PROJECT_ID !== null ? rowData.PROJECT_ID : ""
  );
  const [SoTypeList, setSoTypeList] = useState([]);
  const [ProjectName, setProjectName] = useState(rowData.PROJECT_NAME);
  const [PaymentTerm, setPaymentTerm] = useState(rowData.PAYMENT_TERM);
  //   const [SleSoDate] = useState(new Date(rowData.SO_DATE));
  //   const [SlePoDate, setSlePoDate] = useState(new Date(rowData.PO_DATE));
  const [SleSoDate] = useState(new Date());
  const [SlePoDate, setSlePoDate] = useState(new Date());
  const [SleMemoDate, setSleMemoDate] = useState(new Date());
  const [PoDate, setPoDate] = useState(new Date());
  const [isIncludeSleDo, setIsIncludeSleDo] = useState(false);
  const [OrderType, setOrderType] = useState(rowData.SO_TYPE_DESC);
  const [Remarks, setRemarks] = useState("");
  const [Trigger, setTrigger] = useState("");
  const [blocking, setBlocking] = useState(false);
  const [textInputs, setTextInputs] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [Data, setData] = useState([]);
  const [ProcessedData, setProcessedData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [selectedMemoNum, setSelectedMemoNum] = useState("");
  const [ItemList, setItemList] = useState([]);
  const [RefreshHistoryTrigger, setRefreshHistoryTrigger] = useState("");

  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      CustomerId: rowData.CUSTOMER_ID,
      // ProjectId: rowData.PROJECT_ID,
      OrderType: rowData.SO_TYPE,
      PaymentTerm: rowData.PAYMENT_TERM,
    },
  });

  //   useEffect(() => {
  //     // Create an object to store grouped data
  //     const groupedData = Data.reduce((result, item) => {
  //       const memoNumber = item.MEMO_NUMBER;

  //       // If the memoNumber is not in the result, create a new array for it
  //       if (!result[memoNumber]) {
  //         result[memoNumber] = [];
  //       }

  //       // Push the item into the array under the memoNumber key
  //       result[memoNumber].push(item);

  //       return result;
  //     }, {});

  //     // Convert the grouped data into an array
  //     const groupedArray = Object.values(groupedData);
  //     setProcessedData(groupedArray);
  //   }, [Data]);
  const handleIncludeSleDoCheckbox = (event) => {
    setIsIncludeSleDo(event.target.checked);
  };
  const handleToggleBlocking = (value) => {
    setBlocking(value);
  };

  const handleToggleFromChild = (newValue) => {
    setBlocking(newValue);
  };

  const handleToggleClick = (rowData) => {
    setSelectedRowData(rowData);
    setShowSecondModal("toggle");
  };

  const handleAddItemList = (newValue) => {
    newValue["isNew"] = true;
    setItemList([...ItemList, newValue]);
  };

  const handleEditItemList = (newValue) => {
    const updatedItems = ItemList.map((item) => {
      if (item.ITEM_ID === newValue.ITEM_ID) {
        newValue["isEdit"] = true;
        return newValue; // Replace the matching item
      }
      return item; // Keep other items unchanged
    });

    setItemList(updatedItems);
  };

  const handleDeleteItemList = (newValue) => {
    // const updatedItems = ItemList.filter(
    //   (item) => item.ITEM_ID !== newValue.ITEM_ID
    // );

    // setItemList(updatedItems);
    const updatedItems = ItemList.map((item) => {
      if (item.ITEM_ID === newValue.ITEM_ID) {
        // If ITEM_ID matches, modify the item
        return { ...item, isDelete: true };
      }

      return item; // Otherwise, keep the item as-is
    });
    setItemList(updatedItems);
  };

  const handleNewSo = () => {
    setShowModal("newso");
  };

  const handleEditClick = (rowData) => {
    setSelectedRowData(rowData);
    setShowModal("edit");
  };

  const handleDeleteClick = (rowData) => {
    setSelectedRowData(rowData);
    setShowModal("delete");
  };

  useEffect(() => {
    // handleToggleBlocking();
    getData(config.so_get_link, rowData);
  }, [rowData]);

  useEffect(() => {
    fetch(config.customer_active_list_link, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        handleToggleFromChild(false);
        if (
          jsonData.success !== true &&
          jsonData.message === "Access Denied! Invalid token"
        ) {
          clearUserNameAndTokenInLocalStorage();
          throw new Error(jsonData.message);
        } else if (jsonData.success) {
          return jsonData.data;
        } else {
          throw new Error(jsonData.message);
        }
      })
      .then((data) => {
        setCustomerList(data);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (CustomerId !== "") {
      fetch(config.project_list_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getTokenFromLocalStorage(),
        },
        body: JSON.stringify({
          customer_id: CustomerId,
        }),
      })
        .then((response) => response.json())
        //   .then(res => console.log(res))
        .then((jsonData) => {
          if (
            jsonData.success !== true &&
            jsonData.message === "Access Denied! Invalid token"
          ) {
            clearUserNameAndTokenInLocalStorage();
            throw new Error(jsonData.message);
          } else if (jsonData.success) {
            return jsonData.data;
          } else {
            throw new Error(jsonData.message);
          }
        })
        .then((data) => {
          setProjectList(data);
          //   setValue("ProjectId", "");
          // trigger("ProjectId");
        })
        .catch((error) => console.error(error));
    }
  }, [CustomerId]);

  useEffect(() => {
    fetch(config.sotype_list_link, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        console.log(jsonData);
        if (
          jsonData.success !== true &&
          jsonData.message === "Access Denied! Invalid token"
        ) {
          clearUserNameAndTokenInLocalStorage();
          throw new Error(jsonData.message);
        } else if (jsonData.success) {
          return jsonData.data;
        } else {
          throw new Error(jsonData.message);
        }
      })
      .then((data) => setSoTypeList(data))
      .catch((error) => console.error(error));
  }, []);

  const handleUpdate = async () => {
    try {
      handleToggleBlocking(true);
      var errorCompilation = [];

      // var arrProductId = [];
      // var arrItemQty = [];
      // var arrRestockReq = [];
      // var arrRestockQty = [];
      // var arrRestockDeadline = [];

      var arrNewItemId = [];
      var arrNewProductId = [];
      var arrNewItemQty = [];
      var arrNewRestockReq = [];
      var arrNewRestockQty = [];
      var arrNewRestockDeadline = [];
      var arrNewSellingPrice = [];
      var arrNewCostPrice = [];

      var arrEditItemId = [];
      var arrEditProductId = [];
      var arrEditItemQty = [];
      var arrEditRestockReq = [];
      var arrEditRestockQty = [];
      var arrEditRestockDeadline = [];
      var arrEditSellingPrice = [];
      var arrEditCostPrice = [];

      var arrDeleteItemId = [];

      if (ItemList.length === 0) {
        handleShowAlert(
          "error",
          "Edit Sales Order",
          "Failed Editing Sales Order :",
          ["Order Item is empty!"]
        );
        throw new Error("Order Item is empty!");
      }

      for (var i = 0; i < ItemList.length; i++) {
        if (
          ItemList[i].isNew === true &&
          ItemList[i].isEdit === true &&
          ItemList[i].isDelete === true
        ) {
          continue;
        } else if (ItemList[i].isNew === true) {
          arrNewItemId.push(ItemList[i].ITEM_ID);
          arrNewProductId.push(ItemList[i].PRODUCT_ID);
          arrNewItemQty.push(ItemList[i].ITEM_QTY);
          arrNewRestockReq.push(ItemList[i].RESTOCK_REQ);
          arrNewRestockQty.push(ItemList[i].RESTOCK_QTY);
          arrNewRestockDeadline.push(moment(ItemList[i].RESTOCK_DEADLINE).format("YYYY-MM-DD"));
          arrNewSellingPrice.push(ItemList[i].SELLING_PRICE);
          arrNewCostPrice.push(ItemList[i].COST_PRICE);
        } else if (ItemList[i].isNew === true && ItemList[i].isEdit === true) {
          arrNewProductId.push(ItemList[i].PRODUCT_ID);
          arrNewItemQty.push(ItemList[i].ITEM_QTY);
          arrNewRestockReq.push(ItemList[i].RESTOCK_REQ);
          arrNewRestockQty.push(ItemList[i].RESTOCK_QTY);
          arrNewRestockDeadline.push(moment(ItemList[i].RESTOCK_DEADLINE).format("YYYY-MM-DD"));
          arrNewSellingPrice.push(ItemList[i].SELLING_PRICE);
          arrNewCostPrice.push(ItemList[i].COST_PRICE);
        } else if (ItemList[i].isNew !== true && ItemList[i].isEdit === true) {
          arrEditItemId.push(ItemList[i].ITEM_ID);
          arrEditProductId.push(ItemList[i].PRODUCT_ID);
          arrEditItemQty.push(ItemList[i].ITEM_QTY);
          arrEditRestockReq.push(ItemList[i].RESTOCK_REQ);
          arrEditRestockQty.push(ItemList[i].RESTOCK_QTY);
          arrEditRestockDeadline.push(moment(ItemList[i].RESTOCK_DEADLINE).format("YYYY-MM-DD"));
          arrEditSellingPrice.push(ItemList[i].SELLING_PRICE);
          arrEditCostPrice.push(ItemList[i].COST_PRICE);
        } else if (
          ItemList[i].isNew !== true &&
          ItemList[i].isDelete === true
        ) {
          arrDeleteItemId.push(ItemList[i].ITEM_ID);
        }
        // arrProductId.push(ItemList[i].PRODUCT_ID);
        // arrItemQty.push(ItemList[i].ITEM_QTY);
        // arrRestockReq.push(ItemList[i].RESTOCK_REQ);
        // arrRestockQty.push(ItemList[i].RESTOCK_QTY);
        // arrRestockDeadline.push(
        //   moment(ItemList[i].RESTOCK_DEADLINE).format("YYYY-MM-DD")
        // );
      }

      var jsonObjSoDetails = {
        so_number: SleSoNum,
        customer_id: getValues("CustomerId"),
        so_type: getValues("OrderType"),
        project_id: ProjectId,
        payment_term: getValues("PaymentTerm"),
      };

      var jsonObjNewSoItems = {
        so_number: SleSoNum,
        item_id: arrNewItemId,
        product_id: arrNewProductId,
        item_qty: arrNewItemQty,
        restock_req: arrNewRestockReq,
        restock_qty: arrNewRestockQty,
        restock_deadline: arrNewRestockDeadline,
        selling_price: arrNewSellingPrice,
        cost_price: arrNewCostPrice
      };

      // var jsonObjEditSoItems = {
      //   product_id: arrEditProductId,
      //   item_qty: arrEditItemQty,
      //   restock_req: arrEditRestockReq,
      //   restock_qty: arrEditRestockQty,
      //   restock_deadline: arrEditRestockDeadline,
      // };

      // console.log(jsonObj);

      const updateSoResponse = await fetch(config.so_update_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getTokenFromLocalStorage(),
        },
        body: JSON.stringify(jsonObjSoDetails),
      });

      var contentType = updateSoResponse.headers.get("content-type");
      var data =
        contentType && contentType.includes("application/json")
          ? await updateSoResponse.json()
          : await updateSoResponse.text();
      console.log("result :");
      console.log(data);
      if (data.message === "Access Denied! Invalid token") {
        clearUserNameAndTokenInLocalStorage();
        throw data.message;
      } else if (data.message) {
        throw data.message;
      }
      if (updateSoResponse.status !== 200) {
        var errorMessages = data.error.map(
          (item) => item.msg + " in update SO detail"
        );
        errorCompilation.push(...errorMessages);
        throw errorCompilation;
        // handleShowAlert(
        //   "error",
        //   "New Sales Order",
        //   "Failed Saving New Sales Order :",
        //   errorMessages
        // );
        // throw errorMessages.join("<br/>");
      }

      if (arrNewProductId.length > 0) {
        const createSoItemResponse = await fetch(config.so_item_create_link, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + getTokenFromLocalStorage(),
          },
          body: JSON.stringify(jsonObjNewSoItems),
        });

        var contentType = createSoItemResponse.headers.get("content-type");
        var data =
          contentType && contentType.includes("application/json")
            ? await createSoItemResponse.json()
            : await createSoItemResponse.text();
        console.log("result :");
        console.log(data);
        if (data.message === "Access Denied! Invalid token") {
          clearUserNameAndTokenInLocalStorage();
          throw data.message;
        } else if (data.message) {
          throw data.message;
        }
        if (createSoItemResponse.status !== 200) {
          var errorMessages = data.error.map(
            (item) => item.msg + " in create so"
          );
          errorCompilation.push(...errorMessages);
          throw errorCompilation;
          // var errorMessages = data.error.map((item) => item.msg);
          // handleShowAlert(
          //   "error",
          //   "New Sales Order",
          //   "Failed Saving New Sales Order :",
          //   errorMessages
          // );
          // throw errorMessages.join("<br/>");
        }
      }

      if (arrEditItemId.length > 0) {
        for (var j = 0; j < arrEditItemId.length; j++) {
          const updateSoItemResponse = await fetch(config.so_item_update_link, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + getTokenFromLocalStorage(),
            },
            body: JSON.stringify({
              so_number: SleSoNum,
              item_id: arrEditItemId[j],
              product_id: arrEditProductId[j],
              item_qty: arrEditItemQty[j],
              restock_req: arrEditRestockReq[j],
              restock_qty: arrEditRestockQty[j],
              restock_deadline: arrEditRestockDeadline[j],
              selling_price: arrEditSellingPrice[j],
              cost_price: arrEditCostPrice[j]
            }),
          });

          var contentType = updateSoItemResponse.headers.get("content-type");
          var data =
            contentType && contentType.includes("application/json")
              ? await updateSoItemResponse.json()
              : await updateSoItemResponse.text();
          console.log("result :");
          console.log(data);
          if (data.message === "Access Denied! Invalid token") {
            clearUserNameAndTokenInLocalStorage();
            throw data.message;
          } else if (data.message) {
            throw data.message;
          }
          if (updateSoItemResponse.status !== 200) {
            var errorMessages = data.error.map(
              (item) => item.msg + " in update so item"
            );
            errorCompilation.push(...errorMessages);
            throw errorCompilation;
            // handleShowAlert(
            //   "error",
            //   "New Sales Order",
            //   "Failed Saving New Sales Order :",
            //   errorMessages
            // );
            // throw errorMessages.join("<br/>");
          }
        }
      }

      if (arrDeleteItemId.length > 0) {
        for (var k = 0; k < arrDeleteItemId.length; k++) {
          const deleteSoItemResponse = await fetch(config.so_item_delete_link, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + getTokenFromLocalStorage(),
            },
            body: JSON.stringify({
              item_id: arrDeleteItemId[k],
            }),
          });

          var contentType = deleteSoItemResponse.headers.get("content-type");
          var data =
            contentType && contentType.includes("application/json")
              ? await deleteSoItemResponse.json()
              : await deleteSoItemResponse.text();
          console.log("result :");
          console.log(data);
          if (data.message === "Access Denied! Invalid token") {
            clearUserNameAndTokenInLocalStorage();
            throw data.message;
          } else if (data.message) {
            throw data.message;
          }
          if (deleteSoItemResponse.status !== 200) {
            var errorMessages = data.error.map(
              (item) => item.msg + " in delete so item"
            );
            errorCompilation.push(...errorMessages);
            throw errorCompilation;
            // handleShowAlert(
            //   "error",
            //   "New Sales Order",
            //   "Failed Saving New Sales Order :",
            //   errorMessages
            // );
            // throw errorMessages.join("<br/>");
          }
        }
      }
      if (errorCompilation.length === 0) {
        handleShowAlert(
          "ok",
          "Edit Sales Order",
          "Success Editing Sales Order"
        );
      }
    } catch (error) {
      handleShowAlert(
        "error",
        "Edit Sales Order",
        "Failed Edit Sales Order :",
        error
      );
      console.error("Error while editing Sales Order:", error);
      throw error.join("<br/>");
    } finally {
      handleToggleBlocking(false);
    }
  };

  const getData = async (url, params = {}) => {
    handleToggleBlocking(true);
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify({
        so_number: params.SO_NUMBER,
      }),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        console.log(jsonData);
        // setBlocking(false);
        if (
          jsonData.success !== true &&
          jsonData.message === "Access Denied! Invalid token"
        ) {
          clearUserNameAndTokenInLocalStorage();
          throw new Error(jsonData.message);
        } else if (jsonData.success && jsonData.data.SO_ITEMS.length > 0) {
          const newData = jsonData.data.SO_ITEMS.map((item) => {
            const date = moment(item.SO_DATE).format("DD MMM yyyy");
            item.SO_DATE = date;
            return item;
          });
          return newData;
        } else {
          setItemList([]);
          throw new Error(jsonData.message);
        }
      })
      .then((data) => {
        setItemList(data);
      })
      .catch((error) => console.error(error))
      .finally(() => {
        handleToggleBlocking(false);
      });

    //   .then((response) => response.json())
    //   .then((jsonData) => {
    //     console.log(jsonData);
    //     setBlocking(false);
    //     if (jsonData.message === "Access Denied! Invalid token") {
    //       clearUserNameAndTokenInLocalStorage();
    //       throw new Error(jsonData.message);
    //     }
    //     const newData = jsonData.data.SO_ITEMS.map((item) => {
    //       const date = moment(item.SO_DATE).format("DD MMM yyyy");
    //       item.SO_DATE = date;
    //       return item;
    //     });
    //     return newData;
    //   })
    //   .then((data) => setData(data))
    //   .catch((error) => console.error(error));
  };

  return (
    <Modal show={true} onHide={onClose} size="xl" backdrop="static" centered>
      <BlockUi blocking={blocking}>
        <Form onSubmit={handleSubmit(handleUpdate)}>
          <Modal.Header closeButton className="px-4">
            <Modal.Title className="ms-auto">Edit Sales Order</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-2 justify-content-center">
              <Col md="6">
                <Form.Group as={Row} controlId="SLESONumber">
                  <Form.Label
                    column
                    sm="6"
                    className="fw-bold"
                    style={{ paddingLeft: "100px" }}
                  >
                    SLE SO Number :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={SleSoNum}
                    />
                  </Col>
                </Form.Group>
              </Col>

              <Col md="6">
                <Form.Group as={Row} controlId="SLESONumber">
                  <Form.Label
                    column
                    sm="5"
                    className="fw-bold"
                    style={{ paddingLeft: "100px" }}
                  >
                    SLE SO Status :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={
                        SleSoStatus === "V"
                          ? "Valid"
                          : SleSoStatus === "H"
                          ? "Hold"
                          : SleSoStatus === "C"
                          ? "Cancel"
                          : ""
                      }
                      style={{ paddingLeft: "25px" }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md="6">
                <Form.Group as={Row} controlId="OrderType">
                  <Form.Label
                    column
                    sm="6"
                    className="fw-bold"
                    style={{ paddingLeft: "100px" }}
                  >
                    Order Type :
                  </Form.Label>
                  <Col sm="6">
                    {/* <Controller
                      name="OrderType"
                      control={control}
                      rules={{ required: "This field is required" }}
                      render={({ field }) => (
                        <Form.Select
                          {...field}
                          onChange={(e) => {
                            setOrderType(e.target.value);
                            setValue("OrderType", e.target.value);
                            trigger("OrderType");
                          }}
                          className={`form-select ${errors.OrderType ? "is-invalid" : ""
                            }`}
                        >
                          <option key={""} value="">
                            Please select order type
                          </option>
                          <option key={"Standard Order"} value={"S"}>
                            {"Standard Order"}
                          </option>
                          <option key={"Normal Order"} value={"N"}>
                            {"Normal Order"}
                          </option>
                          <option key={"Trading Order"} value={"T"}>
                            {"Trading Order"}
                          </option>
                          <option key={"Partial Delivery"} value={"P"}>
                            {"Partial Delivery"}
                          </option>
                          <option key={"Bulk Order"} value={"B"}>
                            {"Bulk Order"}
                          </option>
                          {SoTypeList.map((items) => (
                            <option
                              key={items.SO_TYPE}
                              value={items.SO_TYPE}
                            >
                              {items.SO_TYPE_DESC}
                            </option>
                          ))}
                        </Form.Select>
                      )}
                    />
                    {errors.OrderType && (
                      <Form.Text className="text-danger">
                        {errors.OrderType.message}
                      </Form.Text>
                    )} */}
                    <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={OrderType}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group as={Row} controlId="SLESONumber">
                  <Form.Label
                    column
                    sm="5"
                    className="fw-bold"
                    style={{ paddingLeft: "100px" }}
                  >
                    Payment Term :
                  </Form.Label>
                  <Col sm="6" style={{ paddingLeft: "25px" }}>
                    <Controller
                      name="PaymentTerm"
                      control={control}
                      rules={{ required: "This field is required" }}
                      render={({ field }) => (
                        <Form.Select
                          {...field}
                          onChange={(e) => {
                            setPaymentTerm(e.target.value);
                            setValue("PaymentTerm", e.target.value);
                            trigger("PaymentTerm");
                          }}
                          className={`form-select ${
                            errors.PaymentTerm ? "is-invalid" : ""
                          }`}
                        >
                          <option key={""} value="">
                            Please select payment term
                          </option>
                          <option key={"Cash Before Delivery"} value={"CBD"}>
                            {"Cash Before Delivery"}
                          </option>
                          <option key={"Cash On Delivery"} value={"COD"}>
                            {"Cash On Delivery"}
                          </option>
                        </Form.Select>
                      )}
                    />
                    {errors.PaymentTerm && (
                      <Form.Text className="text-danger">
                        {errors.PaymentTerm.message}
                      </Form.Text>
                    )}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            {/* <Row className="mb-2 justify-content-center">
              <Col md="6">
                <Form.Group as={Row} controlId="SLESONumber">
                  <Form.Label column sm="6" className="fw-bold">
                    SLE PO Number :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={SlePoNum}
                    />
                  </Col>
                </Form.Group>
              </Col>

              <Col md="6">
                <Form.Group as={Row} controlId="SLESODate">
                  <Form.Label column sm="6" className="fw-bold">
                    SLE PO Date :
                  </Form.Label>
                  <Col sm="6">
                    <DatePicker
                      readOnly
                      dateFormat="dd/MM/yyyy"
                      className="form-control no-border"
                      showIcon
                      selected={SlePoDate}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row> */}
            <Row className="mb-2">
              <Col md="12">
                <Form.Group as={Row} controlId="CustomerId">
                  <Form.Label
                    column
                    sm="3"
                    className="fw-bold"
                    style={{ paddingLeft: "100px" }}
                  >
                    Customer Name :
                  </Form.Label>
                  <Col sm="4">
                    <Controller
                      name="CustomerId"
                      control={control}
                      rules={{ required: "This field is required" }}
                      render={({ field }) => (
                        <Form.Select
                          {...field}
                          onChange={(e) => {
                            setCustomerId(e.target.value);
                            setValue("CustomerId", e.target.value);
                            // setValue("ProjectId", "");
                            trigger("CustomerId");
                          }}
                          className={`form-select ${
                            errors.CustomerId ? "is-invalid" : ""
                          }`}
                        >
                          <option key={""} value="">
                            Please select customer name
                          </option>
                          {CustomerList.map((items) => (
                            <option
                              key={items.CUSTOMER_ID}
                              value={items.CUSTOMER_ID}
                            >
                              {items.CUSTOMER_NAME}
                            </option>
                          ))}
                        </Form.Select>
                      )}
                    />
                    {errors.CustomerId && (
                      <Form.Text className="text-danger">
                        {errors.CustomerId.message}
                      </Form.Text>
                    )}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md="12">
                <Form.Group as={Row} controlId="ProjectId">
                  <Form.Label
                    column
                    sm="3"
                    className="fw-bold"
                    style={{ paddingLeft: "100px" }}
                  >
                    Project Name:
                  </Form.Label>
                  <Col sm="4">
                    <Form.Select
                      value={ProjectId}
                      onChange={(e) => setProjectId(e.target.value)}
                    >
                      <option key={""} value="">
                        Not Applicable
                      </option>
                      {ProjectList.map((items) => (
                        <option key={items.PROJECT_ID} value={items.PROJECT_ID}>
                          {items.PROJECT_NAME}
                        </option>
                      ))}
                    </Form.Select>
                    {/* <Controller
                      name="ProjectId"
                      control={control}
                      defaultValue={ProjectId}
                      rules={{ required: "This field is required" }}
                      render={({ field }) => (
                        <Form.Select
                          {...field}
                          onChange={(e) => {
                            setProjectId(e.target.value);
                            setValue("ProjectId", e.target.value);
                            trigger("ProjectId");
                          }}
                          className={`form-select ${
                            errors.ProjectId ? "is-invalid" : ""
                          }`}
                        >
                          <option key={""} value="">
                            Please select project name
                          </option>
                          {ProjectList.map((items) => (
                            <option
                              key={items.PROJECT_ID}
                              value={items.PROJECT_ID}
                            >
                              {items.PROJECT_NAME}
                            </option>
                          ))}
                        </Form.Select>
                      )}
                    />
                    {errors.ProjectId && (
                      <Form.Text className="text-danger">
                        {errors.ProjectId.message}
                      </Form.Text>
                    )} */}
                  </Col>
                </Form.Group>
              </Col>
            </Row>

            {/* <Row className="mb-2">
              <Col md="12">
                <Form.Group as={Row} controlId="OrderType">
                  <Form.Label
                    column
                    sm="3"
                    className="fw-bold"
                    style={{ paddingLeft: "100px" }}
                  >
                    Payment Term :
                  </Form.Label>
                  <Col sm="4">
                    <Controller
                      name="PaymentTerm"
                      control={control}
                      rules={{ required: "This field is required" }}
                      render={({ field }) => (
                        <Form.Select
                          {...field}
                          onChange={(e) => {
                            setPaymentTerm(e.target.value);
                            setValue("PaymentTerm", e.target.value);
                            trigger("PaymentTerm");
                          }}
                          className={`form-select ${errors.PaymentTerm ? "is-invalid" : ""
                            }`}
                        >
                          <option key={""} value="">
                            Please select payment term
                          </option>
                          <option key={"Cash Before Delivery"} value={"CBD"}>
                            {"Cash Before Delivery"}
                          </option>
                          <option key={"Cash On Delivery"} value={"COD"}>
                            {"Cash On Delivery"}
                          </option>
                        </Form.Select>
                      )}
                    />
                    {errors.PaymentTerm && (
                      <Form.Text className="text-danger">
                        {errors.PaymentTerm.message}
                      </Form.Text>
                    )}
                  </Col>
                </Form.Group>
              </Col>
            </Row> */}
            <hr />
            <Row className="mb-4 align-items-center">
              <Col sm="9" className="text-center">
                <Form.Label>[ Order Item ]</Form.Label>
              </Col>
              <Col sm="3">
                <Button
                  className="me-2"
                  variant="secondary"
                  onClick={handleNewSo}
                >
                  Add Item
                </Button>
              </Col>
            </Row>
            <EditModalTable
              itemList={ItemList}
              onEditClick={handleEditClick}
              onDeleteClick={handleDeleteClick}
              OrderType={OrderType}
              //   setSelectedMemoNum={setSelectedMemoNum}
              //   setShowSecondModal={setShowSecondModal}
              // setSelectedRowData = {setSelectedRowData}
              // onChildToggleChange={handleToggleFromChild}
              // onRowSelect={toggleRowSelection}
              // onInputChange={handleInputChange}
              // selectedRows={selectedRows}
              // setSelectedRows={setSelectedRows}
              // textInputs={textInputs}
              // setTextInputs={setTextInputs}
              // toggleRowSelection={toggleRowSelection}
            />
            {/* {showSecondModal === "edit" ? (
              <ManageBulkOrderMemoHistoryEditModal
                handleCloseAlert={handleCloseAlert}
                handleShowAlert={handleShowAlert}
                rowData={rowData}
                selectedMemoNum={selectedMemoNum}
                onClose={() => setShowSecondModal(false)}
                setTrigger={setTrigger}
                setRefreshHistoryTrigger={setRefreshHistoryTrigger}
              />
            ) : (
              ""
            )} */}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onClose} variant="secondary">
              Close
            </Button>
            <Button type="submit" variant="primary">
              Save
            </Button>
          </Modal.Footer>
        </Form>
        {showModal === "newso" ? (
          <ManageSONewItemModal
            handleAddItemList={handleAddItemList}
            handleCloseAlert={handleCloseAlert}
            handleShowAlert={handleShowAlert}
            itemList={ItemList}
            rowData={selectedRowData}
            onClose={() => setShowModal(false)}
            orderType={rowData.SO_TYPE}
          />
        ) : (
          ""
        )}
        {showModal === "edit" ? (
          <ManageSOEditItemModal
            handleEditItemList={handleEditItemList}
            handleShowAlert={handleShowAlert}
            itemList={ItemList}
            rowData={selectedRowData}
            onClose={() => setShowModal(false)}
            orderType={rowData.SO_TYPE}
          />
        ) : (
          ""
        )}
        {showModal === "delete" ? (
          <ManageSODeleteItemModal
            handleDeleteItemList={handleDeleteItemList}
            handleShowAlert={handleShowAlert}
            rowData={selectedRowData}
            onClose={() => setShowModal(false)}
          />
        ) : (
          ""
        )}
      </BlockUi>
    </Modal>
  );
}

export default EditModal;
