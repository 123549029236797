import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Row, Col, Alert } from 'react-bootstrap';
import { storeUserNameAndTokenInLocalStorage } from '../lib/common';
import { useNavigate, useLocation } from 'react-router-dom';
import BlockUi from '@availity/block-ui';
import "@availity/block-ui/src/BlockUi.css";
import "@availity/block-ui/src/Loader.css";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState([]);
  const [blocking, setBlocking] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  useEffect(() => {
    if (state !== null){
      if (state.data !== undefined){
        setError([{msg : state.data}]);
      }
    }
  }, []);

  const handleToggleBlocking = () => {
    setBlocking(prevState => !prevState);
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform login logic here
    const response = await fetch(config.login_link, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: username,
        password: password
      })
    });

    const contentType = response.headers.get('content-type');
    const data = contentType && contentType.includes('application/json')
      ? await response.json()
      : await response.text();


    if (data.message === "Logged in!") {
      console.log(data.message);
      storeUserNameAndTokenInLocalStorage(data.user_name, data.token);
      navigate("/");
    } else if (data === "User Name or password is incorrect!"){
      setError([{msg : data}]);
    }
    else {
      console.log(data)
      setError(data.errors || [{msg : "An error occurred."}]);
    }
    handleToggleBlocking();
    return;
  };

  return (
    <Container>
      <BlockUi blocking={blocking}>
      <Row className="justify-content-center mt-5">
        <Col xs={10} sm={8} md={6} lg={4}>
          <h1 className="text-center mb-4">Login Page</h1>
          {error && error.length > 0 && (
              <Alert variant="danger">
                {error.map((errorMsg, index) => (
                  <p key={index}>{errorMsg.msg}</p>
                ))}
              </Alert>
          )}
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formUsername" className="mb-2">
              <Form.Label>Username</Form.Label>
              <Form.Control
                value={username}
                type="text"
                placeholder="Enter username"
                onChange={
                  (e) => {
                    setError('');
                    setUsername(e.target.value);
                  }
                }
              />
            </Form.Group>

            <Form.Group controlId="formPassword" className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                value={password}
                type="password"
                placeholder="Password"
                onChange={                  
                  (e) => {
                    setError('');
                    setPassword(e.target.value);
                  }
                }
              />
            </Form.Group>

            <Button variant="primary" type="submit" className="w-100" onClick={handleToggleBlocking}>
              Login
            </Button>
          </Form>
        </Col>
      </Row>
      </BlockUi>
    </Container>
  );
}

export default Login;