import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Pagination from "react-bootstrap/Pagination";
import moment from "moment/moment";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function IssuePOModalNonConsignmentTable(props) {
  const { onEditClick } = props;
  const { onDeleteClick } = props;
  const { textInputs1 } = props;
  const { setTextInputs1 } = props;
  const { onInputChange1 } = props;
  const { textInputs2 } = props;
  const { setTextInputs2 } = props;
  const { onInputChange2 } = props;
  const { itemList } = props;
  const { isIssued } = props;
  const { control } = props;
  const { register } = props;
  const { errors } = props;
  const [data, setData] = useState([]);
  const [IsIssued, setIsIssued] = useState({ status: false, message: "" });
  const [headerData] = useState([
    "Brand",
    "Supplier Item Name",
    "Supplier Item Code",
    "UOM",
    "SO Qty",
    "Qty",
    "Cost Price",
    "Action",
  ]);
  const [currentPage, setCurrentPage] = useState(1);

  const ROWS_PER_PAGE = 10;
  const totalPages = Math.ceil(data.length / ROWS_PER_PAGE);

  useEffect(() => {
    setData(itemList);
    setIsIssued(isIssued);
  }, [itemList]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleToggleBlocking = () => {
    props.onChildToggleChange(false);
  };

  const formatNumberWithCommas = (number) => {
    return parseFloat(number)
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, "$&,");
  };

  const handleInputChange1 = (event, rowId) => {
    const { name, value } = event.target;
    setTextInputs1({ ...textInputs1, [rowId]: value });
    onInputChange1(rowId, value); // Call the callback function in the parent component
  };

  const handleInputChange2 = (event, rowId) => {
    const { name, value } = event.target;
    setTextInputs2({ ...textInputs2, [rowId]: value });
    onInputChange2(rowId, value); // Call the callback function in the parent component
  };

  const renderTableData = () => {
    const startIndex = (currentPage - 1) * ROWS_PER_PAGE;
    const endIndex = startIndex + ROWS_PER_PAGE;
    return data.slice(startIndex, endIndex).map((row) => {
      const {
        CUSTOM_ID,
        ITEM_ID,
        BRAND,
        PRODUCT_NAME,
        ITEM_QTY,
        UNIT_MEASURE,
        UNIT_PRICE,
        RESTOCK_REQ,
        RESTOCK_QTY,
        RESTOCK_DEADLINE,
        SELLING_PRICE,
        COST_PRICE,
        SUPPLIER_ITEM_NAME,
        SUPPLIER_ITEM_CODE,
        SO_QTY,
      } = row;
      return (
        <tr key={ITEM_ID}>
          <td>{BRAND}</td>
          <td>{SUPPLIER_ITEM_NAME}</td>
          <td>{SUPPLIER_ITEM_CODE}</td>
          <td>{UNIT_MEASURE}</td>
          <td className="text-end">{SO_QTY}</td>
          <td style={{ width: "190px" }}>
            <Form.Control
              type="text"
              placeholder="Quantity"
              name={`textInput-${ITEM_ID}`}
              value={textInputs1[ITEM_ID] || ""}
              className={`form-control ${errors[ITEM_ID] ? "is-invalid" : ""}`}
              onInput={(e) => handleInputChange1(e, ITEM_ID)}
              {...register(ITEM_ID, {
                required: "This field is required",
                pattern: {
                  value: /^(?!0+(\.0*)?$)\d+(\.\d{1,4})?$/,
                  message: "Up to 4 decimal value",
                },
              })}
            />
            {errors[ITEM_ID] && (
              <Form.Text className="text-danger">
                {errors[ITEM_ID].message}
              </Form.Text>
            )}
          </td>
          <td style={{ width: "190px" }}>
            <Form.Control
              type="text"
              placeholder="Cost Price"
              name={`textInput2-${CUSTOM_ID}`}
              value={textInputs2[CUSTOM_ID] || ""}
              className={`form-control ${errors[CUSTOM_ID] ? "is-invalid" : ""}`}
              onInput={(e) => handleInputChange2(e, CUSTOM_ID)}
              {...register(CUSTOM_ID, {
                required: "This field is required",
                pattern: {
                  value: /^(?:\d+|\d*\.\d{1,2})$/,
                  // value: /^(?!^(\d+|\d*\.\d{0,1})$)\d+(\.\d{1,2})?$/,
                  message: "Needs integer or 2 decimal value",
                },
                // validate: (value) => {
                //   const intValue = parseInt(value, 10);
                //   return (
                //     intValue <= OrderQuantity ||
                //     `Enter a positive integer up to ${OrderQuantity}`
                //   );
                // },
              })}
            />
            {errors[CUSTOM_ID] && (
              <Form.Text className="text-danger">
                {errors[CUSTOM_ID].message}
              </Form.Text>
            )}
          </td>
          <td><Button variant="primary" onClick={() => onDeleteClick(row)}>Delete</Button></td>
          {/* <td className="text-end">
            {COST_PRICE !== null &&
            COST_PRICE !== undefined &&
            COST_PRICE !== ""
              ? formatNumberWithCommas(COST_PRICE)
              : "-"}
          </td> */}
          {/* <td className="text-end">
            {UNIT_PRICE !== null &&
            UNIT_PRICE !== undefined &&
            UNIT_PRICE !== ""
              ? formatNumberWithCommas(UNIT_PRICE)
              : "-"}
          </td> */}
          {/* <td className="text-end">
            {SELLING_PRICE !== null &&
            SELLING_PRICE !== undefined &&
            SELLING_PRICE !== ""
              ? formatNumberWithCommas(SELLING_PRICE)
              : "-"}
          </td>
          <td>{RESTOCK_REQ === "Y" ? "Yes" : "No"}</td>
          <td className="text-end">
            {parseInt(RESTOCK_QTY) > 0 ? RESTOCK_QTY : "-"}
          </td>
          <td>
            {RESTOCK_DEADLINE !== null
              ? moment(RESTOCK_DEADLINE).format("DD MMM yyyy")
              : "-"}
          </td>
          <td>{RESTOCK_REQ === "Y" ? "No" : "-"}</td> */}
          {/* <td style={{ width: "215px" }}>
            <Button
              style={{ marginRight: "5px" }}
              variant="primary"
              onClick={() => onEditClick(row)}
            >
              Edit
            </Button>
            <Button variant="primary" onClick={() => onDeleteClick(row)}>
              Delete
            </Button>
          </td> */}
        </tr>
      );
    });
  };

  const renderPageItems = () => {
    const pageItems = [];
    for (let i = 1; i <= totalPages; i++) {
      pageItems.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }
    return pageItems;
  };

  //   const getData = async (url, params = {}) => {
  //     await fetch(url,{
  //       method: 'POST',
  //       headers: {
  //         "Content-Type": "application/json",
  //         "Authorization" : "Bearer " + getTokenFromLocalStorage()
  //       },
  //       body : JSON.stringify(params.length === 0 ? params : {
  //         customer_name : params.CustomerName
  //       })
  //     })
  //     .then(response => response.json())
  //     .then (jsonData => {
  //         console.log(jsonData)
  //       handleToggleBlocking();
  //       if (jsonData.message === 'Access Denied! Invalid token'){
  //         clearUserNameAndTokenInLocalStorage();
  //         throw new Error (jsonData.message);
  //       }
  //       return jsonData;
  //     })
  //     .then(data => setData(data))
  //     .catch(error => console.error(error));
  //   }

  if (data.length < 1 && IsIssued.status === true) {
    return (
      <div className="text-center">
        <h3>{IsIssued.message}</h3>
      </div>
      // <br></br>
    );
  } else if (data.length < 1) {
    return (
      // <div className="text-center"><h3>All PO has been issued</h3></div>
      <br></br>
    );
  } else {
    return (
      <div>
        <Table striped bordered hover className="mb-4">
          <thead>
            <tr>
              {headerData.map((header) => (
                <th key={header}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>{renderTableData()}</tbody>
        </Table>
        <Pagination className="d-flex justify-content-center">
          {renderPageItems()}
        </Pagination>
      </div>
    );
  }
}

export default IssuePOModalNonConsignmentTable;
