import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Pagination from 'react-bootstrap/Pagination';
import moment from 'moment/moment';
import { clearUserNameAndTokenInLocalStorage, getTokenFromLocalStorage } from '../../lib/common';
const config = JSON.parse(process.env.REACT_APP_CONFIG);

function ManageBulkOrderNewTable(props) {
    const [data, setData] = useState([]);
    const [headerData] = useState(["Supplier Name", "Product Name", "Total Quantity", "Balance Quantity", "Quantity"]);
    const [currentPage, setCurrentPage] = useState(1);
    //   const { onOnHoldClick } = props;
    //   const { onRestockClick } = props;
    //   const { onCancelClick} = props;
    //   const { searchProps } = props;

    const { showAlert, rowData, onRowSelect, selectedRows, setSelectedRows, textInputs, setTextInputs, onInputChange, toggleRowSelection } = props;
    const ROWS_PER_PAGE = 10;
    const totalPages = Math.ceil(data.length / ROWS_PER_PAGE);

    useEffect(() => {
        getData(config.memo_item_pending_link, rowData);
    }, [rowData, showAlert]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleToggleBlocking = () => {
        props.onChildToggleChange(false);
    };

    const handleInputChange = (event, rowId) => {
        const { name, value } = event.target;
        setTextInputs({ ...textInputs, [rowId]: value });
        onInputChange(rowId, value); // Call the callback function in the parent component
    };
    const renderTableData = () => {
        const startIndex = (currentPage - 1) * ROWS_PER_PAGE;
        const endIndex = startIndex + ROWS_PER_PAGE;
        return data.slice(startIndex, endIndex).map((row) => {
            const { PRODUCT_ID, SUPPLIER_NAME, PRODUCT_NAME, ITEM_QTY, TOTAL_MEMO_QTY } = row;
            return (
                <tr key={PRODUCT_ID}>
                    <td>
                        <Form.Check
                            type="checkbox"
                            onChange={() => toggleRowSelection(row.PRODUCT_ID)}
                            checked={selectedRows.includes(row.PRODUCT_ID)}
                        />
                    </td>
                    <td>{SUPPLIER_NAME}</td>
                    <td>{PRODUCT_NAME}</td>
                    <td>{TOTAL_MEMO_QTY}</td>
                    <td>{ITEM_QTY}</td>
                    <td>
                        <Form.Control
                            type="text"
                            name={`textInput-${row.PRODUCT_ID}`}
                            value={textInputs[row.PRODUCT_ID] || ''}
                            onChange={(e) => handleInputChange(e, row.PRODUCT_ID)}
                        />
                    </td>
                </tr>
            );
        });
    };

    const renderPageItems = () => {
        const pageItems = [];
        for (let i = 1; i <= totalPages; i++) {
            pageItems.push(
                <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
                    {i}
                </Pagination.Item>
            );
        }
        return pageItems;
    };

    const getData = async (url, params = {}) => {
        await fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify(
                {
                    so_number: params.SO_NUMBER
                }
            )
        })
            .then(response => response.json())
            .then(jsonData => {
                console.log(jsonData)
                handleToggleBlocking();
                if (jsonData.message === 'Access Denied! Invalid token') {
                    clearUserNameAndTokenInLocalStorage();
                    throw new Error(jsonData.message);
                }
                const newData = jsonData.data.map(item => {
                    const date = moment(item.SO_DATE).format("DD MMM yyyy");
                    item.SO_DATE = date;
                    return item;
                });
                return newData;
            })
            .then(data => setData(data))
            .catch(error => console.error(error));
    }

    if (data.length < 1) {
        return (
            <div className="text-center"><h3>No Data</h3></div>
        )
    } else {
        return (
            <div>
                <Table striped bordered hover className="mb-4">
                    <thead>
                        <tr>
                            <th>
                                <Form.Check
                                    type="checkbox"
                                    onChange={() => {
                                        if (selectedRows.length === data.length) {
                                            setSelectedRows([]);
                                        } else {
                                            setSelectedRows(data.map((row) => row.PRODUCT_ID));
                                        }
                                    }}
                                    checked={selectedRows.length === data.length}
                                />
                            </th>
                            {headerData.map((header) => (
                                <th key={header}>{header}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>{renderTableData()}</tbody>
                </Table>
                <Pagination className="d-flex justify-content-center" >{renderPageItems()}</Pagination>
            </div>
        );
    }

}

export default ManageBulkOrderNewTable;