import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment/moment';
import { useNavigate } from 'react-router-dom';
import { clearUserNameAndTokenInLocalStorage, getUserNameFromLocalStorage, getTokenFromLocalStorage } from '../lib/common';
import BlockUi from '@availity/block-ui';
import "@availity/block-ui";
import AlertModal from '../page_components/AlertModal';
import { IconContext } from "react-icons";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useForm, Controller } from "react-hook-form";
const config = JSON.parse(process.env.REACT_APP_CONFIG);

function SleSo() {
  const { control, register, handleSubmit, reset, formState: { errors } } = useForm({
    defaultValues : {
      SleSoNum : "",
      SleSoDate : new Date(),
      radioValue : "N"
    }
  });
  const [SleSoDate, setSleSoDate] = useState(new Date());
  const [SleSoNum, setSleSoNum] = useState("")
  const [radioValue, setRadioValue] = useState("N");
  const [blocking, setBlocking] = useState(false);
  const [ShowAlert, setShowAlert] = useState(false);
  const [AlertTitle, setAlertTitle] = useState("");
  const [AlertMessage, setAlertMessage] = useState("");
  const [ApiResponse, setApiResponse] = useState("");
  const navigate = useNavigate();

  const handleShowAlert = async (status, title, message, apiResponse = "") => {
    setAlertTitle(title);
    if (status === "ok"){
      setAlertMessage(
        <>
          <p className='mb-1'>
            <IconContext.Provider value={{ color: "green", style : {fontSize : "40px", paddingRight : "5px"}}}>
              <AiOutlineCheckCircle />
              {message}
            </IconContext.Provider>
          </p>
        </>
        );
    } else {
      setAlertMessage(
        <>
          <p className='mb-1'>
            <IconContext.Provider value={{ color: "red", style : {fontSize : "40px", paddingRight : "5px"}}}>
              <AiOutlineCloseCircle />
              {message}
            </IconContext.Provider>
          </p>
          {apiResponse !== "" ? <p className='text-danger fst-italic ps-3'>{apiResponse}</p>
          :""}
        </>
        );
    }
    setShowAlert(true);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  useEffect(() => {
    if (getTokenFromLocalStorage() === null){
      const state = { data: 'Expired token' };
      navigate("/login", { state });
    }
  });

  const handleToggleBlocking = () => {
    setBlocking(prevState => !prevState);
  };

  const handleResetForm = () => {
    reset();
    setSleSoDate(new Date());
    setSleSoNum("");
    setRadioValue("N");
  }
  
  const handleSave = (event) => {
    event.preventDefault();
    handleToggleBlocking();
    submitData();
  }

  const submitData = () => {
    fetch(config.so_create_link, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization" : "Bearer " + getTokenFromLocalStorage()
      },
      body: JSON.stringify({
        so_number: SleSoNum,
        so_date: moment(SleSoDate).format("YYYY-MM-DD"),
        so_type : radioValue,
        user_modify : getUserNameFromLocalStorage()
      })
    })
    .then(response => {
      handleToggleBlocking();
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        return response.json();
      } else {
        return response.text();
      }
    })
    .then(data => {
      if (data.message === 'Access Denied! Invalid token'){
        clearUserNameAndTokenInLocalStorage();
        throw (data.message);
      }
      if (data.errors){
        var errorMessages = data.errors.map(item => item.msg);
        handleShowAlert("error", "New Sales Order", "Failed Saving New Sales Order :", errorMessages.join("<br>"));
        throw (data.errors);
      }
      if (data.affectedRows > 0){
        handleShowAlert("ok", "New Sales Order", "Success Saving New Sales Order");
        setSleSoNum("");
      } else {
        handleShowAlert("error", "New Sales Order", "Failed Saving New Sales Order :", data.message);
        throw (data.message);
      }
    })
    .catch(error => {
      console.error(error);
    });
  }
  return (
    <div>
      <Container className="mt-4">
        <BlockUi blocking={blocking}>
          <Form onSubmit={handleSubmit(handleSave)}>
            <Row className="justify-content-md-center mb-4">
              <Col md="auto">
                <h2>New Sales Order</h2>
              </Col>
            </Row>
            <Row className="mb-2 justify-content-center">
              <Col md="4">
                <Form.Group as={Row} className="mb-3" controlId="SLESONumber">
                  <Form.Label column sm="5">
                    SLE SO Number
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control type="text" placeholder="SLE SO Number" value={SleSoNum} onInput={(e) => setSleSoNum(e.target.value)}
                    {...register('SleSoNum', { required: 'SO Number is required', pattern: {value : /^(SO)-\d{4}(0[1-9]|1[0-2])\d{4}$/, message : "Invalid SO Number"}  })}/>
                    {errors.SleSoNum && <p className='text-danger'>{errors.SleSoNum.message}</p>}
                  </Col>
                </Form.Group>
              </Col>

              <Col md="4">
                <Form.Group as={Row} className="mb-3" controlId="SLESODate">
                    <Form.Label column sm="5">
                      SLE SO Date
                    </Form.Label>
                    <Col sm="6">
                      <Controller
                        name="SleSoDate"
                        control={control}
                        defaultValue={new Date()}
                        required = "SO Date Required"
                        rules={{ required: true, pattern : {value : /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/, message : "Invalid date format"} }}
                        render={({ field,  fieldState: { error } }) => (
                          <>
                            <DatePicker
                              selected={field.value}
                              onChange={(date) => field.onChange(date)}
                              className={`form-control ${error ? 'is-invalid' : ''}`}
                              dateFormat="dd/MM/yyyy"
                            />
                            {error && <span className="text-danger">SO Date is required</span>}
                          </>
                        )}
                      />
                    </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-4 justify-content-center">
              <Col xs lg="2">
                <Form.Check
                label="Normal Order"
                name="radio1"
                type="radio"
                value="N"
                checked = {radioValue === "N"}
                id={`inline-radio-1`}
                onChange={(e) => setRadioValue(e.currentTarget.value)}
              />
            </Col>
            <Col xs lg="2">
              <Form.Check
                label="Bulk Order"
                name="radio2"
                type="radio"
                value="B"
                checked = {radioValue === "B"}
                id={`inline-radio-2`}
                onChange={(e) => setRadioValue(e.currentTarget.value)}
              />
            </Col>

            <Col xs lg="2">
              <Form.Check
                label="Partial Delivery"
                name="radio3"
                type="radio"
                value="P"
                checked = {radioValue === "P"}
                id={`inline-radio-3`}
                onChange={(e) => setRadioValue(e.currentTarget.value)}
              />
            </Col>
          </Row>
          <div className="d-flex justify-content-center">
            <Button className='me-2' variant="secondary" onClick={handleResetForm}>Reset</Button>{' '}
            <Button type="submit" variant="primary">Save</Button>{' '}
          </div>
        </Form>
        </BlockUi>
      </Container>
      {ShowAlert && (
        <AlertModal
          title={AlertTitle}
          message={AlertMessage}
          apiResponse={ApiResponse}
          onClose={handleCloseAlert}
        />
      )}
    </div>
);
}

export default SleSo;
