import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SearchDeliveryOrderTable from "../page_components/SearchDeliveryOrder/SearchDeliveryOrder_Table";
import ManageBulkOrderMemoHistoryModal from "../page_components/ManageBulkOrderMemo/ManageBulkOrderMemo_HistoryModal";
import ManageBulkOrderMemoNewModal from "../page_components/ManageBulkOrderMemo/ManageBulkOrderMemo_NewModal";
import SearchDeliveryOrderViewModal from "../page_components/SearchDeliveryOrder/SearchDeliveryOrder_ViewModal";
import SearchDeliveryOrderFollowUpModal from "../page_components/SearchDeliveryOrder/SearchDeliveryOrder_FollowUpModal";
import SearchDeliveryOrderDeleteConfirmationModal from "../page_components/SearchDeliveryOrder/SearchDeliveryOrder_DeleteConfirmationModal";
import SearchDeliveryOrderUpdateConfirmationModal from "../page_components/SearchDeliveryOrder/SearchDeliveryOrder_UpdateConfirmationModal";
import SearchDeliveryOrderEditModal from "../page_components/SearchDeliveryOrder/SearchDeliveryOrder_EditModal";
import ManageSoOnHoldModal from "../page_components/ManageSO/ManageSO_OnHoldModal";
import ManageSoRestockModal from "../page_components/ManageSO/ManageSO_RestockModal";
import ManageSoCancelModal from "../page_components/ManageSO/ManageSO_CancelModal";
import ManagePoDeleteConfirmationModal from "../page_components/ManagePO/ManagePO_DeleteConfirmationModal";
import ManageSoRestockToggleModal from "../page_components/ManageRestockRequest/ManageRestockRequest_FollowupToggleModal";
import { useNavigate } from "react-router-dom";
import {
  clearUserNameAndTokenInLocalStorage,
  getTokenFromLocalStorage,
} from "../lib/common";
import BlockUi from "@availity/block-ui";
import "@availity/block-ui/src/BlockUi.css";
import "@availity/block-ui/src/Loader.css";
import AlertModal from "../page_components/AlertModal";
import { IconContext } from "react-icons";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { AiOutlineCloseCircle } from "react-icons/ai";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function SearchDeliveryOrder() {
  const [SleSoDateFrom, setSleSoDateFrom] = useState("");
  const [SleSoDateTo, setSleSoDateTo] = useState("");
  const [SleDoDateFrom, setSleDoDateFrom] = useState("");
  const [SleDoDateTo, setSleDoDateTo] = useState("");
  const [ScheduleDateFrom, setScheduleDateFrom] = useState("");
  const [ScheduleDateTo, setScheduleDateTo] = useState("");
  const [SleSoNum, setSleSoNum] = useState("");
  const [SlePoNum, setSlePoNum] = useState("");
  const [SleDoNum, setSleDoNum] = useState("");
  const [CustomerName, setCustomerName] = useState("");
  const [CustomerList, setCustomerList] = useState([]);
  const [ProjectName, setProjectName] = useState("");
  const [ProjectList, setProjectList] = useState([]);
  const [isPendingRestock, setIsPendingRestock] = useState(false);
  const [searchProps, setSearchProps] = useState({});
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [blocking, setBlocking] = useState(true);
  const [ShowAlert, setShowAlert] = useState(false);
  const [AlertTitle, setAlertTitle] = useState("");
  const [AlertMessage, setAlertMessage] = useState("");
  const [ApiResponse, setApiResponse] = useState("");
  const [ShowFollowUpModal, setShowFollowUpModal] = useState(false);
  const [ShowDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [ShowUpdateConfirmationModal, setShowUpdateConfirmationModal] =
    useState(false);
  const [UpdateConfirmationText, setUpdateConfirmationText] = useState("");
  const [ConstructedPayload, setConstructedPayload] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    if (getTokenFromLocalStorage() === null) {
      const state = { data: "Expired token" };
      navigate("/login", { state });
    }
  });

  useEffect(() => {
    fetch(config.customer_list_link, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        console.log(jsonData);
        if (
          jsonData.success !== true &&
          jsonData.message === "Access Denied! Invalid token"
        ) {
          clearUserNameAndTokenInLocalStorage();
          throw new Error(jsonData.message);
        } else if (jsonData.success) {
          return jsonData.data;
        } else {
          throw new Error(jsonData.message);
        }
      })
      .then((data) => setCustomerList(data))
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (CustomerName !== "") {
      fetch(config.project_list_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getTokenFromLocalStorage(),
        },
        body: JSON.stringify({
          customer_id: CustomerName,
        }),
      })
        .then((response) => response.json())
        .then((jsonData) => {
          console.log(jsonData);
          if (
            jsonData.success !== true &&
            jsonData.message === "Access Denied! Invalid token"
          ) {
            clearUserNameAndTokenInLocalStorage();
            throw new Error(jsonData.message);
          } else if (jsonData.success) {
            return jsonData.data;
          } else {
            throw new Error(jsonData.message);
          }
        })
        .then((data) => setProjectList(data))
        .catch((error) => console.error(error));
    }
  }, [CustomerName]);

  const handleShowAlert = async (status, title, message, apiResponse = "") => {
    setAlertTitle(title);
    if (status === "ok") {
      setAlertMessage(
        <>
          <p className="mb-1">
            <IconContext.Provider
              value={{
                color: "green",
                style: { fontSize: "40px", paddingRight: "5px" },
              }}
            >
              <AiOutlineCheckCircle />
              {message}
            </IconContext.Provider>
          </p>
          {apiResponse !== "" ? (
            <p className="ps-3">Delivery Order Number : {apiResponse[0]}</p>
          ) : (
            ""
          )}
        </>
      );
    } else {
      setAlertMessage(
        <>
          <p className="mb-1">
            <IconContext.Provider
              value={{
                color: "red",
                style: { fontSize: "40px", paddingRight: "5px" },
              }}
            >
              <AiOutlineCloseCircle />
              {message}
            </IconContext.Provider>
          </p>
          {apiResponse.length > 0
            ? apiResponse.map((response) => (
                <p className="text-danger fst-italic ps-3">{response}</p>
              ))
            : ""}
        </>
      );
    }
    setShowAlert(true);
  };

  const handleCheckboxChange = () => {
    setIsPendingRestock(!isPendingRestock);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const handleToggleBlocking = () => {
    setBlocking((prevState) => !prevState);
  };

  const handleToggleFromChild = (newValue) => {
    setBlocking(newValue);
  };

  const handleResetForm = () => {
    handleToggleBlocking();

    setSleSoDateFrom("");
    setSleSoDateTo("");
    setSleDoDateFrom("");
    setSleDoDateTo("");
    setScheduleDateFrom("");
    setScheduleDateTo("");
    setSleSoNum("");
    setSleDoNum("");
    setCustomerName("");
    setProjectName("");
    setProjectList([]);

    const searchProperties = {
      SleSoDateFrom: "",
      SleSoDateTo: "",
      SleDoDateFrom: "",
      SleDoDateTo: "",
      SleSoNum: "",
      SleDoNum: "",
      ScheduleDateFrom: "",
      ScheduleDateTo: "",
      CustomerName: "",
      ProjectName: "",
    };
    setSearchProps(searchProperties);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    const searchProperties = {
      SleSoDateFrom: SleSoDateFrom,
      SleSoDateTo: SleSoDateTo,
      SleDoDateFrom: SleDoDateFrom,
      SleDoDateTo: SleDoDateTo,
      SleSoNum: SleSoNum,
      ScheduleDateFrom: ScheduleDateFrom,
      ScheduleDateTo: ScheduleDateTo,
      SleDoNum: SleDoNum,
      CustomerName: CustomerName,
      ProjectName: ProjectName,
    };
    setSearchProps(searchProperties);
  };

  const handleIssuePOClick = (rowData) => {
    setSelectedRowData(rowData);
    setShowModal(true);
  };

  const handleOnHoldClick = (rowData) => {
    console.log(rowData);
    setSelectedRowData(rowData);
    setShowModal("onhold");
  };

  const handleRestockClick = (rowData) => {
    console.log(rowData);
    setSelectedRowData(rowData);
    setShowModal("restock");
  };

  const handleCancelClick = (rowData) => {
    console.log(rowData);
    setSelectedRowData(rowData);
    setShowModal("cancel");
  };

  const handleDeleteClick = (rowData) => {
    console.log(rowData);
    setSelectedRowData(rowData);
    setShowModal("delete");
  };

  const handleViewClick = (rowData) => {
    console.log(rowData);
    setSelectedRowData(rowData);
    setShowModal("view");
  };

  const handleEditClick = (rowData) => {
    console.log(rowData);
    setSelectedRowData(rowData);
    setShowModal("edit");
  };

  const handleShowFollowUp = (rowData) => {
    // console.log(rowData);
    // setSelectedRowData(rowData);
    setShowFollowUpModal(true);
  };
  const handleShowDeleteConfirmation = (rowData) => {
    // console.log(rowData);
    setSelectedRowData(rowData);
    setShowDeleteConfirmationModal(true);
  };

  const handleShowUpdateConfirmation = (text) => {
    // console.log(rowData);
    // setSelectedRowData(rowData);
    setUpdateConfirmationText(text);
    setShowUpdateConfirmationModal(true);
  };

  const handleOnClose = (isFinished) => {
    setShowUpdateConfirmationModal(false);
    if (isFinished) {
      setShowModal(false);
    }
  }

  return (
    <div>
      <Container className="mt-4">
        <BlockUi blocking={blocking}>
          <Row className="justify-content-center mb-4">
            <Col md={7}>
              <Form onSubmit={handleSearch}>
                <Row className="mb-4">
                  <Col className="text-center">
                    <h2>Search Delivery Order</h2>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col sm="7">
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="SLESONumber"
                    >
                      <Form.Label column sm="6">
                        SLE SO #
                      </Form.Label>
                      <Col sm="5">
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={SleSoNum}
                          onInput={(e) => setSleSoNum(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>

                  <Col sm="5">
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="SLEDONumber"
                    >
                      <Form.Label column sm="5" className="text-center">
                        SLE DO #
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={SleDoNum}
                          onInput={(e) => setSleDoNum(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>

                  {/* <Col sm="6">
                    <Form.Group as={Row} controlId="SLEPONumber">
                      <Form.Label column sm="4" style={{paddingLeft: "34px"}}>
                        SLE PO #
                      </Form.Label>
                      <Col sm="6">
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={SlePoNum}
                          onInput={(e) => setSlePoNum(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col> */}
                </Row>
                <Row className="mb-2">
                  <Col sm="6">
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="SLESODateFrom"
                    >
                      <Form.Label column sm="7">
                        SLE SO Date From
                      </Form.Label>
                      <Col sm="5">
                        <DatePicker
                          className="form-control"
                          dateFormat="dd/MM/yyyy"
                          showIcon
                          selected={SleSoDateFrom}
                          onChange={(date) => setSleSoDateFrom(date)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col sm="6">
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="SLESODateTo"
                    >
                      <Form.Label column sm="2" style={{ paddingLeft: "25px" }}>
                        to
                      </Form.Label>
                      <Col sm="5">
                        <DatePicker
                          className="form-control"
                          dateFormat="dd/MM/yyyy"
                          showIcon
                          selected={SleSoDateTo}
                          onChange={(date) => setSleSoDateTo(date)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col sm="6">
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="SLEDODateFrom"
                    >
                      <Form.Label column sm="7">
                        SLE DO Date From
                      </Form.Label>
                      <Col sm="5">
                        <DatePicker
                          className="form-control"
                          dateFormat="dd/MM/yyyy"
                          showIcon
                          selected={SleDoDateFrom}
                          onChange={(date) => setSleDoDateFrom(date)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col sm="6">
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="SLEDODateTo"
                    >
                      <Form.Label column sm="2" style={{ paddingLeft: "25px" }}>
                        to
                      </Form.Label>
                      <Col sm="5">
                        <DatePicker
                          className="form-control"
                          dateFormat="dd/MM/yyyy"
                          showIcon
                          selected={SleDoDateTo}
                          onChange={(date) => setSleDoDateTo(date)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col sm="6">
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="SLESODateFrom"
                    >
                      <Form.Label column sm="7" style={{fontSize : "95%"}}>
                        Schedule Delivery Date From
                      </Form.Label>
                      <Col sm="5">
                        <DatePicker
                          className="form-control"
                          dateFormat="dd/MM/yyyy"
                          showIcon
                          selected={ScheduleDateFrom}
                          onChange={(date) => setScheduleDateFrom(date)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col sm="6">
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="SLESODateTo"
                    >
                      <Form.Label column sm="2" style={{ paddingLeft: "25px" }}>
                        to
                      </Form.Label>
                      <Col sm="5">
                        <DatePicker
                          className="form-control"
                          dateFormat="dd/MM/yyyy"
                          showIcon
                          selected={ScheduleDateTo}
                          onChange={(date) => setScheduleDateTo(date)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="CustomerName"
                    >
                      <Form.Label column sm="3">
                        Customer Name
                      </Form.Label>
                      <Col sm="5" style={{ paddingRight: "3px" }}>
                        <Form.Select
                          value={CustomerName}
                          onChange={(e) => setCustomerName(e.target.value)}
                        >
                          <option key={""} value="">
                            Please select customer name
                          </option>
                          {CustomerList.map((items) => (
                            <option
                              key={items.CUSTOMER_ID}
                              value={items.CUSTOMER_ID}
                            >
                              {items.CUSTOMER_NAME}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="ProjectName"
                    >
                      <Form.Label column sm="3">
                        Project Name
                      </Form.Label>
                      <Col sm="5" style={{ paddingRight: "3px" }}>
                        <Form.Select
                          value={ProjectName}
                          onChange={(e) => setProjectName(e.target.value)}
                        >
                          <option key={""} value="">
                            Please select project name
                          </option>
                          {ProjectList.map((items) => (
                            <option
                              key={items.PROJECT_ID}
                              value={items.PROJECT_ID}
                            >
                              {items.PROJECT_NAME}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-center">
                    <Button
                      className="me-2"
                      variant="secondary"
                      onClick={handleResetForm}
                    >
                      Reset
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      onClick={handleToggleBlocking}
                    >
                      Search
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <hr />
          <br />
          <SearchDeliveryOrderTable
            showAlert={ShowAlert}
            searchProps={searchProps}
            onChildToggleChange={handleToggleFromChild}
            onOnHoldClick={handleOnHoldClick}
            onRestockClick={handleRestockClick}
            onCancelClick={handleCancelClick}
            onDeleteClick={handleShowDeleteConfirmation}
            onViewClick={handleViewClick}
            onEditClick={handleEditClick}
          />

          {showModal === "view" ? (
            <SearchDeliveryOrderViewModal
              handleCloseAlert={handleCloseAlert}
              handleShowAlert={handleShowAlert}
              handleShowFollowUp={handleShowFollowUp}
              // handleShowDeleteConfirmation={handleShowDeleteConfirmation}
              rowData={selectedRowData}
              onClose={() => setShowModal(false)}
            />
          ) : (
            ""
          )}
          {showModal === "edit" ? (
            <SearchDeliveryOrderEditModal
              handleCloseAlert={handleCloseAlert}
              handleShowAlert={handleShowAlert}
              handleSetConstructedPayload={setConstructedPayload}
              handleShowUpdateConfirmation={handleShowUpdateConfirmation}
              rowData={selectedRowData}
              onClose={() => setShowModal(false)}
            />
          ) : (
            ""
          )}
          {ShowFollowUpModal === true ? (
            <SearchDeliveryOrderFollowUpModal
              handleCloseAlert={handleCloseAlert}
              handleShowAlert={handleShowAlert}
              rowData={selectedRowData}
              onClose={() => setShowFollowUpModal(false)}
            />
          ) : (
            ""
          )}
          {ShowDeleteConfirmationModal === true ? (
            <SearchDeliveryOrderDeleteConfirmationModal
              handleCloseAlert={handleCloseAlert}
              handleShowAlert={handleShowAlert}
              rowData={selectedRowData}
              onClose={() => {
                setShowDeleteConfirmationModal(false);
                setShowModal(false);
              }}
            />
          ) : (
            ""
          )}
          {ShowUpdateConfirmationModal === true ? (
            <SearchDeliveryOrderUpdateConfirmationModal
              UpdateConfirmationText={UpdateConfirmationText}
              handleCloseAlert={handleCloseAlert}
              handleShowAlert={handleShowAlert}
              constructedPayload={ConstructedPayload}
              rowData={selectedRowData}
              onClose={handleOnClose}
            />
          ) : (
            ""
          )}
        </BlockUi>
        {ShowAlert && (
          <AlertModal
            title={AlertTitle}
            message={AlertMessage}
            apiResponse={ApiResponse}
            onClose={handleCloseAlert}
          />
        )}
      </Container>
    </div>
  );
}

export default SearchDeliveryOrder;
