import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import {
  clearUserNameAndTokenInLocalStorage,
  getUserNameFromLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
import BlockUi from "@availity/block-ui";
import { useForm, Controller } from "react-hook-form";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function IssuePoModal(props) {
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      PoDate: new Date(),
    },
  });
  const { rowData, onClose, handleShowAlert, handleCloseAlert } = props;
  const [SleSoNum] = useState(rowData.SO_NUMBER);
  const [SlePoNum, setSlePoNum] = useState("");
  const [SleMemoNum, setSleMemoNum] = useState("");
  const [SleDoNum, setSleDoNum] = useState("");
  const [CustomerId] = useState(rowData.CUSTOMER_ID);
  const [CustomerName, setCustomerName] = useState(rowData.CUSTOMER_NAME);
  const [ProjectId] = useState(rowData.PROJECT_ID);
  const [ProjectName, setProjectName] = useState(rowData.PROJECT_NAME);
  const [PaymentTerm] = useState(rowData.PAYMENT_TERM);
  const [SleSoDate] = useState(new Date(rowData.SO_DATE));
  const [SlePoDate, setSlePoDate] = useState(new Date());
  const [SleMemoDate, setSleMemoDate] = useState(new Date());
  const [PoDate, setPoDate] = useState(new Date());
  const [isIncludeSleDo, setIsIncludeSleDo] = useState(false);
  const [OrderType] = useState(rowData.SO_TYPE_DESC);
  const [Remarks, setRemarks] = useState("");
  const [Trigger, setTrigger] = useState("");
  const [blocking, setBlocking] = useState(false);
  const [maxDate] = useState(new Date());
  const [minDate] = useState(() => {
    const maxDate = new Date();
    maxDate.setMonth(new Date().getMonth() - 1);
    const formattedMaxDate = maxDate;
    return formattedMaxDate;
  });
  const navigate = useNavigate();

  const handleIncludeSleDoCheckbox = (event) => {
    setIsIncludeSleDo(event.target.checked);
  };
  const handleToggleBlocking = () => {
    setBlocking((prevState) => !prevState);
  };

  const handleToggleFromChild = (newValue) => {
    setBlocking(newValue);
  };

  const handleSaveNewPo = async () => {
    try {
      handleToggleBlocking();
      await submitNewPo();
      handleToggleBlocking();
      console.log("Success saving New Purchase Order");
      onClose();
      handleShowAlert("ok", "New Purchase Order", "Success saving New Purchase Order");
    } catch (error) {
      handleToggleBlocking();
      console.error("Error while saving New Purchase Order:", error);
      handleShowAlert(
        "error",
        "New Purchase Order",
        "Error while saving New Purchase Order: ",
        error.join("<br>")
      );
      return;
    }
  };

  const submitNewPo = async () => {
    try {
      const response = await fetch(config.po_create_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getTokenFromLocalStorage(),
        },
        body: JSON.stringify({
          po_type: "S",
          so_number: SleSoNum,
          po_date: moment(PoDate).format("YYYY-MM-DD")
        }),
      });

      const data = await response.json();
      console.log("submit po response : ");
      console.log(data);
      if (
        data.success !== true &&
        data.message === "Access Denied! Invalid token"
      ) {
        clearUserNameAndTokenInLocalStorage();
      }

      if (data.success !== true) {
        var errorMessages = data.error.map((item) => item.msg);
        // handleShowAlert("error", "New Invoice", "Failed Saving New Invoice :", errorMessages.join("<br>"));
        throw errorMessages;
      }
      if (data.success === true) {
        console.log("Success Submitting Customer");
        // handleTrigger(moment().unix());
        return "ok";
      } else {
        throw data;
      }
    } catch (error) {
      console.error("Error while submitting PO:", error);
      throw error;
    }
  };

  return (
    <Modal show={true} onHide={onClose} size="xl" backdrop="static" centered>
      <BlockUi blocking={blocking}>
        <Form onSubmit={handleSubmit(handleSaveNewPo)}>
          <Modal.Header closeButton className="px-4">
            <Modal.Title className="ms-auto">New Purchase Order</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-2 justify-content-center" style={{paddingLeft : "160px"}}>
              <Col md="6">
                <Form.Group as={Row} controlId="SLESONumber">
                  <Form.Label column sm="4" className="fw-bold">
                    SLE SO Number :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={SleSoNum}
                    />
                  </Col>
                </Form.Group>
              </Col>

              <Col md="6">
                <Form.Group as={Row} controlId="SLESODate">
                  <Form.Label column sm="4" className="fw-bold">
                    SLE SO Date :
                  </Form.Label>
                  <Col sm="7">
                    <DatePicker
                      readOnly
                      dateFormat="dd/MM/yyyy"
                      className="form-control no-border"
                      showIcon
                      selected={SleSoDate}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2 justify-content-center" style={{paddingLeft : "160px"}}>
              <Col md="6">
                <Form.Group as={Row} controlId="SLESONumber">
                  <Form.Label column sm="4" className="fw-bold">
                    Customer Name :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={CustomerName}
                    />
                  </Col>
                </Form.Group>
              </Col>

              <Col md="6">
                <Form.Group as={Row} controlId="SLESODate">
                  <Form.Label column sm="4" className="fw-bold">
                    Project Name:
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={ProjectName}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row style={{paddingLeft : "160px"}}>
              <Col md="6">
                <Form.Group as={Row} controlId="OrderType">
                  <Form.Label column sm="4" className="fw-bold">
                    Order Type :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={OrderType}
                    />
                  </Col>
                </Form.Group>
              </Col>

              <Col md="6">
                <Form.Group as={Row} controlId="OrderType">
                  <Form.Label column sm="4" className="fw-bold">
                    Order Status :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={PaymentTerm}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <hr />
            <Row className="mb-2">
              <Col md="12">
                <Form.Group as={Row} controlId="PoDate" className="justify-content-end">
                  <Form.Label column sm="9" className="fw-bold text-end">
                    PO Date :
                  </Form.Label>
                  <Col sm="2">
                    {/* <DatePicker
                      dateFormat="dd/MM/yyyy"
                      className="form-control"
                      showIcon
                      selected={PoDate}
                      onChange={(date) => setPoDate(date)}
                    /> */}
                    <Controller
                      name="PoDate"
                      control={control}
                      rules={{
                        required: "This field is required",
                        pattern: {
                          value:
                            /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/,
                          message: "Invalid date format",
                        },
                      }}
                      render={({ field }) => (
                        <>
                          <DatePicker
                            selected={field.value}
                            onChange={(date) => field.onChange(date)}
                            minDate={minDate}
                            maxDate={maxDate}
                            className={`form-control ${errors.PoDate ? "is-invalid" : ""
                              }`}
                          />
                          {errors.PoDate && (
                            <Form.Text className="text-danger">
                              {errors.PoDate.message}
                            </Form.Text>
                          )}
                        </>
                      )}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onClose} variant="secondary">
              Cancel
            </Button>
            <Button
              type="submit"
              variant="primary"
              
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </BlockUi>
    </Modal>
  );
}

export default IssuePoModal;
