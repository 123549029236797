import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import ManageBulkOrderMemoEditTable from "./NewDeliveryOrder_EditTable";
import NewDeliveryOrderNewNonBulkTable from "./NewDeliveryOrder_NewNonBulkTable";
import ManageBulkOrderMemoHistoryDeleteConfirmationModal from "./NewDeliveryOrder_DeleteConfirmationModal";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import {
  clearUserNameAndTokenInLocalStorage,
  getUserNameFromLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
import BlockUi from "@availity/block-ui";
import { useForm, Controller } from "react-hook-form";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function NewDeliveryOrderNewNonBulkModal(props) {
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ScheduleDate : new Date(),
      DoDate : new Date()
    }
  });

  const {
    rowData,
    selectedMemoNum,
    onClose,
    handleShowAlert,
    handleCloseAlert,
    setTrigger,
  } = props;
  const [Data, setData] = useState([]);
  const [SleSoNum] = useState(rowData.SO_NUMBER);
  // const [SlePoNum, setSlePoNum] = useState(rowData.PO_NUMBER);
  const [SleMemoNum, setSleMemoNum] = useState(selectedMemoNum);
  const [SleDoNum, setSleDoNum] = useState("");
  const [CustomerId] = useState(rowData.CUSTOMER_ID);
  const [CustomerName, setCustomerName] = useState(rowData.CUSTOMER_NAME);
  const [ProjectId] = useState(rowData.PROJECT_ID !== null ? rowData.PROJECT_ID : "");
  const [ProjectName, setProjectName] = useState(rowData.PROJECT_NAME !== null ? rowData.PROJECT_NAME : "");
  const [PaymentTerm] = useState(rowData.PAYMENT_TERM);
  const [SleSoDate] = useState(new Date(rowData.SO_DATE));
  // const [SlePoDate, setSlePoDate] = useState("");
  // const [SlePoDate, setSlePoDate] = useState(new Date(rowData.PO_DATE));
  // const [PoDate, setPoDate] = useState("");
  // const [PoDate, setPoDate] = useState(new Date());
  const [isIncludeSleDo, setIsIncludeSleDo] = useState(false);
  const [OrderType] = useState(rowData.SO_TYPE);
  const [Remarks, setRemarks] = useState("");
  const [ScheduleDate, setScheduleDate] = useState(new Date());
  const [DoDate] = useState(new Date());
  const [blocking, setBlocking] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [textInputs, setTextInputs] = useState({});
  const [selectedData, setSelectedData] = useState([]);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState("");
  const [fieldRequirements, setFieldRequirements] = useState({});
  const [minDate] = useState(new Date());
  const [maxDate] = useState(() => {
    const maxDate = new Date();
    maxDate.setMonth(new Date().getMonth() + 1);
    const formattedMaxDate = maxDate;
    return formattedMaxDate;
  });
  const navigate = useNavigate();
  const handleIncludeSleDoCheckbox = (event) => {
    setIsIncludeSleDo(event.target.checked);
  };
  const handleToggleBlocking = () => {
    setBlocking((prevState) => !prevState);
  };

  const handleToggleFromChild = (newValue) => {
    setBlocking(newValue);
  };

  const handleDeleteClick = () => {
    setShowDeleteConfirmationModal(true);
  };

  const handleCheckboxChange = (fieldName) => {
    setFieldRequirements((prevRequirements) => ({
      ...prevRequirements,
      [fieldName]: !prevRequirements[fieldName],
    }));
  };

  const handleSetData = (data) => {
    setData(data);
  }

  const handleSelectAll = () => {
    const allSelected = Object.values(fieldRequirements).every(Boolean);
    const updatedRequirements = {};
    Data.forEach((field) => {
      updatedRequirements[field.ROW_KEY] = !allSelected;
    });
    setFieldRequirements(updatedRequirements);
  };

  const handleSubmitNewNonBulkDeliveryOrder = async (event) => {
    // event.preventDefault();
    try {
      handleToggleBlocking();
      var submitNewNonBulkDeliveryOrderResponse = await submitNewNonBulkDeliveryOrder();
      console.log("Success creating new delivery order");
      onClose();
      handleShowAlert("ok", "New Delivery Order", "Success creating New Delivery Order", [submitNewNonBulkDeliveryOrderResponse.do_number]);
    } catch (error) {
      handleToggleBlocking();
      console.error("Error while creating new delivery order:", error);
      handleShowAlert(
        "error",
        "New Delivery Order",
        "Error while creating new delivery order: ",
        error.join("<br>")
      );
      return;
    }
  };

  const submitNewNonBulkDeliveryOrder = async () => {
    try {
      const item_qtis = Object.values(textInputs);
      // console.log(fieldRequirements)
      if (selectedRows.length === 0) {
        throw ["Please specify item(s) to be included in this DO"];
      }
      const product_id = () => {
        const array = [];
        for (var i in selectedRows) {
          array.push(selectedRows[i].split("_")[1]);
        }
        return array;
      }
      const item_qty = () => {
        const array = [];
        for (var j=0; j<item_qtis.length; j++){
          if (j < selectedRows.length){
            array.push(parseFloat(item_qtis[j]).toFixed(4));
          }
        }
        return array;
      }
    
      const response = await fetch(config.do_create_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getTokenFromLocalStorage(),
        },
        body: JSON.stringify({
          so_number: rowData.SO_NUMBER,
          // po_number: rowData.PO_NUMBER !== undefined ? rowData.PO_NUMBER : "",
          schedule_delivery_date: moment(ScheduleDate).format("YYYY-MM-DD"),
          do_date: moment(DoDate).format("YYYY-MM-DD"),
          remarks: "",
          product_id: product_id(),
          item_qty: item_qty(),
          customer_id : rowData.CUSTOMER_ID
        })
      });

      const data = await response.json();
      console.log("creating new delivery order response : ");
      console.log(data);
      if (
        data.success !== true &&
        data.message === "Access Denied! Invalid token"
      ) {
        clearUserNameAndTokenInLocalStorage();
      }

      if (data.success !== true) {
        var errorMessages = data.error.map((item) => item.msg);
        // handleShowAlert("error", "New Invoice", "Failed Saving New Invoice :", errorMessages.join("<br>"));
        throw errorMessages;
      }
      setTrigger(moment().unix());
      if (data.success === true) {
        console.log("Success creating new delivery order");
        // handleTrigger(moment().unix());
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      console.error("Error while creating new delivery order:", error);
      throw error;
    }
  };

  const toggleRowSelection = (rowId) => {
    if (selectedRows.includes(rowId)) {
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
    } else {
      setSelectedRows([...selectedRows, rowId]);
    }
  };

  const handleInputChange = (rowId, value) => {
    setTextInputs({ ...textInputs, [rowId]: value });
  };

  const handleSaveSelectedData = (event) => {
    // event.preventDefault();
    var item_qtis = Object.values(textInputs);
    var dataSend = {
      product_id: selectedRows,
      item_qty: item_qtis.map((item_qty) => parseInt(item_qty)),
    };

    // You can perform additional actions with the selected data here
  };

  return (
    <Modal
      show={true}
      onHide={onClose}
      size="xl"
      backdrop="static"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      centered
    >
      <BlockUi blocking={blocking}>
        <Form onSubmit={handleSubmit(handleSubmitNewNonBulkDeliveryOrder)}>
          <Modal.Header closeButton className="px-4">
            <Modal.Title className="ms-auto">New Delivery Order</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-2 justify-content-center" style={{ marginLeft: "70px" }}>
              <Col md="6">
                <Form.Group as={Row} controlId="SLESONumber">
                  <Form.Label column sm="4" className="fw-bold">
                    SLE SO Number :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={SleSoNum}
                    />
                  </Col>
                </Form.Group>
              </Col>

              <Col md="6">
                <Form.Group as={Row} controlId="SLESODate">
                  <Form.Label column sm="4" className="fw-bold">
                    SLE SO Date :
                  </Form.Label>
                  <Col sm="6" style={{ marginLeft: "-30px" }}>
                    <DatePicker
                      readOnly
                      dateFormat="dd/MM/yyyy"
                      className="form-control no-border"
                      showIcon
                      selected={SleSoDate}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            {/* <Row className="mb-2 justify-content-center" style={{ marginLeft: "70px" }}>
              <Col md="6">
                <Form.Group as={Row} controlId="SLESONumber">
                  <Form.Label column sm="4" className="fw-bold">
                    SLE PO Number :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={SlePoNum}
                    />
                  </Col>
                </Form.Group>
              </Col>

              <Col md="6">
                <Form.Group as={Row} controlId="SLESODate">
                  <Form.Label column sm="4" className="fw-bold">
                    SLE PO Date :
                  </Form.Label>
                  <Col sm="6" style={{ marginLeft: "-30px" }}>
                    <DatePicker
                      readOnly
                      dateFormat="dd/MM/yyyy"
                      className="form-control no-border"
                      showIcon
                      selected={SlePoDate}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row> */}
            <Row className="mb-2" style={{ marginLeft: "70px" }}>
              <Col md="6">
                <Form.Group as={Row} controlId="OrderType">
                  <Form.Label column sm="4" className="fw-bold">
                    Order Type :
                  </Form.Label>
                  <Col sm="6" >
                    <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={OrderType}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group as={Row} controlId="OrderType">
                  <Form.Label column sm="4" className="fw-bold" >
                    Payment Term :
                  </Form.Label>
                  <Col sm="6" style={{ marginLeft: "-30px" }}>
                    <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={PaymentTerm}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2" style={{ marginLeft: "70px" }}>
              <Col md="12">
                <Form.Group as={Row} controlId="SLESONumber">
                  <Form.Label column sm="2" className="fw-bold" >
                    Customer Name :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={CustomerName}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2" style={{ marginLeft: "70px" }}>
              <Col md="12">
                <Form.Group as={Row} controlId="SLESODate">
                  <Form.Label column sm="2" className="fw-bold">
                    Project Name :
                  </Form.Label>
                  <Col sm="7" >
                    <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={ProjectName}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            
            {/* <Row className="mb-2">
            <Col md="12">
              <Form.Group as={Row} controlId="OrderType">
                <Form.Label column sm="3" className="fw-bold">
                  Payment Term :
                </Form.Label>
                <Col sm="6">
                  <Form.Control
                    readOnly
                    className="no-border"
                    type="text"
                    placeholder=""
                    value={PaymentTerm}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row> */}
            <hr />
            <Row className="mb-2" style={{marginLeft : "70px"}}>
              <Col md="6">
                <Form.Group as={Row} controlId="OrderType">
                  <Form.Label column sm="4" className="fw-bold" >
                    DO Date :
                  </Form.Label>
                  <Col sm="6" >
                  <Controller
                      name="DoDate"
                      control={control}
                      rules={{
                        required: "This field is required",
                        pattern: {
                          value:
                            /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/,
                          message: "Invalid date format",
                        },
                      }}
                      render={({ field }) => (
                        <>
                          <DatePicker
                            disabled={true}
                            selected={field.value}
                            dateFormat="dd/MM/yyyy"
                            onChange={(date) => field.onChange(date)}
                            minDate={minDate}
                            maxDate={maxDate}
                            className={`form-control ${errors.DoDate ? "is-invalid" : ""
                              }`}
                          />
                          {errors.DoDate && (
                            <Form.Text className="text-danger">
                              {errors.DoDate.message}
                            </Form.Text>
                          )}
                        </>
                      )}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group as={Row} controlId="OrderType">
                  <Form.Label column sm="5" className="fw-bold">
                    Schedule Delivery Date :
                  </Form.Label>
                  <Col sm="4">
                    <Controller
                      name="ScheduleDate"
                      control={control}
                      rules={{
                        required: "This field is required",
                        pattern: {
                          value:
                            /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/,
                          message: "Invalid date format",
                        },
                      }}
                      render={({ field }) => (
                        <>
                          <DatePicker
                            selected={field.value}
                            onChange={(date) => {
                              field.onChange(date);
                              setScheduleDate(date);
                            }}
                            dateFormat="dd/MM/yyyy"
                            minDate={minDate}
                            maxDate={maxDate}
                            className={`form-control ${errors.ScheduleDate ? "is-invalid" : ""
                              }`}
                          />
                          {errors.ScheduleDate && (
                            <Form.Text className="text-danger">
                              {errors.ScheduleDate.message}
                            </Form.Text>
                          )}
                        </>
                      )}
                    />
                    {/* <DatePicker
                      dateFormat="dd/MM/yyyy"
                      className="form-control"
                      showIcon
                      selected={ScheduleDate}
                      onChange={(date) => setScheduleDate(date)}
                    /> */}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <hr />
            <Row className="mb-4 align-items-center">
              <Col sm="12" className="text-center">
                <Form.Label>[ Order Item ]</Form.Label>
              </Col>
            </Row>
            <NewDeliveryOrderNewNonBulkTable
              rowData={rowData}
              handleSetData={handleSetData}
              selectedMemoNum={selectedMemoNum}
              onChildToggleChange={handleToggleFromChild}
              onRowSelect={toggleRowSelection}
              onInputChange={handleInputChange}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              textInputs={textInputs}
              setTextInputs={setTextInputs}
              toggleRowSelection={toggleRowSelection}
              refreshTrigger={refreshTrigger}
              Data={Data}
              fieldRequirements={fieldRequirements}
              control={control}
              register={register}
              errors={errors}
              handleSelectAll={handleSelectAll}
              handleCheckboxChange={handleCheckboxChange}
              reset={reset}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onClose} variant="secondary">
              Cancel
            </Button>
            {/* <Button onClick={handleDeleteClick} variant="secondary">
            Delete
          </Button> */}
            <Button
              type="submit"
              variant="primary"
            // onClick={() => handleSubmitNewNonBulkDeliveryOrder()}
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </BlockUi>
    </Modal>
  );
}

export default NewDeliveryOrderNewNonBulkModal;
