import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SLEPOTable from "../page_components/SLEPO/SLEPO_Table";
import CustomersTable from "../page_components/Customers/Customers_CustomersTable";
import NewCustomerModal from "../page_components/Customers/Customers_NewCustomerModal";
import EditCustomerModal from "../page_components/Customers/Customers_EditCustomerModal";
import { useNavigate } from "react-router-dom";
import { getTokenFromLocalStorage } from "../lib/common";
import BlockUi from "@availity/block-ui";
import "@availity/block-ui/src/BlockUi.css";
import "@availity/block-ui/src/Loader.css";
import AlertModal from "../page_components/AlertModal";
import { IconContext } from "react-icons";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useForm, Controller } from "react-hook-form";

function Customers() {
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      CustomerName: "",
    },
  });

  const [CustomerName, setCustomerName] = useState("");
  const [searchProps, setSearchProps] = useState({});
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [blocking, setBlocking] = useState(true);
  const [ShowAlert, setShowAlert] = useState(false);
  const [AlertTitle, setAlertTitle] = useState("");
  const [AlertMessage, setAlertMessage] = useState("");
  const [ApiResponse, setApiResponse] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (getTokenFromLocalStorage() === null) {
      const state = { data: "Expired token" };
      navigate("/login", { state });
    }
  });

  const handleShowAlert = async (status, title, message, apiResponse = []) => {
    setAlertTitle(title);
    if (status === "ok") {
      setAlertMessage(
        <>
          <p className="mb-1">
            <IconContext.Provider
              value={{
                color: "green",
                style: { fontSize: "40px", paddingRight: "5px" },
              }}
            >
              <AiOutlineCheckCircle />
              {message}
            </IconContext.Provider>
          </p>
        </>
      );
    } else {
      setAlertMessage(
        <>
          <p className="mb-1">
            <IconContext.Provider
              value={{
                color: "red",
                style: { fontSize: "40px", paddingRight: "5px" },
              }}
            >
              <AiOutlineCloseCircle />
              {message}
            </IconContext.Provider>
          </p>
          {apiResponse.length > 0
            ? apiResponse.map((response) => (
                <p className="text-danger fst-italic ps-3">{response}</p>
              ))
            : ""}
        </>
      );
    }
    setShowAlert(true);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const handleToggleBlocking = () => {
    setBlocking((prevState) => !prevState);
  };

  const handleToggleFromChild = (newValue) => {
    setBlocking(newValue);
  };

  // const handleResetForm = () => {
  //   handleToggleBlocking();

  //   const searchProperties = {
  //     CustomerName : ""
  //   };
  //   setSearchProps(searchProperties);
  // }

  const handleResetForm = async () => {
    reset();
    handleToggleBlocking();
    setCustomerName("");
    const searchProperties = {
      CustomerName: "",
    };
    setSearchProps(searchProperties);
  };

  const handleSearch = () => {
    // event.preventDefault();
    handleToggleBlocking();
    const searchProperties = {
      CustomerName: CustomerName,
    };
    setSearchProps(searchProperties);
  };

  //   const handleIssuePOClick = (rowData) => {
  //     setSelectedRowData(rowData);
  //     setShowModal(true);
  //   };

  const handleNewCostumer = () => {
    setShowModal("newcustomer");
  };
  const handleEditClick = (rowData) => {
    setSelectedRowData(rowData);
    setShowModal("edit");
  };

  return (
    <div>
      <Container className="mt-4">
        <BlockUi blocking={blocking}>
          <Row className="justify-content-center mb-4">
            <Col md={6}>
              <Form onSubmit={handleSubmit(handleSearch)}>
                <Row className="mb-4">
                  <Col className="text-center">
                    <h2>Customers</h2>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="CustomerName"
                    >
                      <Form.Label column sm="4">
                        Customer Name
                      </Form.Label>
                      <Col sm="7" style={{ paddingRight: "3px" }}>
                        <Form.Control
                          type="text"
                          placeholder="Customer Name"
                          value={CustomerName}
                          onInput={(e) => setCustomerName(e.target.value)}
                          {...register("CustomerName", {
                            pattern: {
                              value: /^.{3,}$/,
                              message: "Minimum 3 Character",
                            },
                            validate: (value) => {
                              return value !== "" || "Please specify criteria";
                            },
                          })}
                        />
                        {errors.CustomerName && (
                          <p className="text-danger">
                            {errors.CustomerName.message}
                          </p>
                        )}
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-center">
                    <Button
                      className="me-2"
                      variant="secondary"
                      onClick={handleResetForm}
                    >
                      Reset
                    </Button>
                    <Button type="submit" variant="primary">
                      Search
                    </Button>
                    <Button
                      className="ms-2"
                      variant="primary"
                      onClick={handleNewCostumer}
                    >
                      New Customer
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <hr />
          <br />
          <CustomersTable
            showAlert={ShowAlert}
            searchProps={searchProps}
            onChildToggleChange={handleToggleFromChild}
            onEditClick={handleEditClick}
          />
          {showModal === "newcustomer" ? (
            <NewCustomerModal
              handleShowAlert={handleShowAlert}
              onClose={() => setShowModal(false)}
            />
          ) : (
            ""
          )}
          {showModal === "edit" ? (
            <EditCustomerModal
              handleShowAlert={handleShowAlert}
              rowData={selectedRowData}
              onClose={() => setShowModal(false)}
            />
          ) : (
            ""
          )}
        </BlockUi>
        {ShowAlert && (
          <AlertModal
            title={AlertTitle}
            message={AlertMessage}
            apiResponse={ApiResponse}
            onClose={handleCloseAlert}
          />
        )}
      </Container>
    </div>
  );
}

export default Customers;
