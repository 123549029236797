import React, { useState } from 'react';
import BlockUi from '@availity/block-ui';
import "@availity/block-ui/src/BlockUi.css";
import "@availity/block-ui/src/Loader.css";
import { useNavigate } from 'react-router-dom';
import AlertModal from '../page_components/AlertModal';
import { IconContext } from "react-icons";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import { clearUserNameAndTokenInLocalStorage, getUserNameFromLocalStorage, getTokenFromLocalStorage} from '../lib/common';
const config = JSON.parse(process.env.REACT_APP_CONFIG);

function ChangePassword() {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [blocking, setBlocking] = useState(false);
  const [ShowAlert, setShowAlert] = useState(false);
  const [AlertTitle, setAlertTitle] = useState("");
  const [AlertMessage, setAlertMessage] = useState("");
  const [ApiResponse, setApiResponse] = useState("");

  const handleShowAlert = async (status, title, message, apiResponse = "") => {
    setAlertTitle(title);
    if (status === "ok"){
      setAlertMessage(
        <>
          <p className='mb-1'>
            <IconContext.Provider value={{ color: "green", style : {fontSize : "40px", paddingRight : "5px"}}}>
              <AiOutlineCheckCircle />
              {message}
            </IconContext.Provider>
          </p>
        </>
        );
    } else {
      setAlertMessage(
        <>
          <p className='mb-1'>
            <IconContext.Provider value={{ color: "red", style : {fontSize : "40px", paddingRight : "5px"}}}>
              <AiOutlineCloseCircle />
              {message}
            </IconContext.Provider>
          </p>
          {apiResponse !== "" ? <p className='text-danger fst-italic ps-3'>{apiResponse}</p>
          :""}
        </>
        );
    }
    setShowAlert(true);
  };

  const handleResetForm = () => {
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
  }

  const handleToggleBlocking = () => {
    setBlocking(prevState => !prevState);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate password fields
    
    if (newPassword !== confirmPassword) {
        handleToggleBlocking();
        handleShowAlert("error", "Change Password", "Failed changing Password :", ["New Password and Confirm New Password doesn't match"])
        return;
    } else {
        console.log(config.change_password_link);
        fetch(config.change_password_link, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              "Authorization" : "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({
              username: getUserNameFromLocalStorage(),
              password: currentPassword,
              new_password : newPassword,
            })
          })
          .then(response => {
            handleToggleBlocking();
            const contentType = response.headers.get('content-type');
            if (contentType && contentType.includes('application/json')) {
              return response.json();
            } else {
              return response.text();
            }
          })
          .then(data => {
            if (data.message === 'Access Denied! Invalid token'){
                clearUserNameAndTokenInLocalStorage();
                throw (data.message);
            } else if (data.message === 'current password is incorrect!'){
                handleShowAlert("error", "Change Password", "Failed changing Password :", [data.message])
                throw (data.message);
            } else if (data.errors){
                var errorMessages = data.errors.map(item => item.msg);
                handleShowAlert("error", "Change Password", "Failed changing Password :", errorMessages.join("<br>"));
                throw (data.errors);
            }
            
            if (data.message === "Password changed successfully!"){
                handleShowAlert("ok", "Change Password", "Success changing Password");
                setPasswordChanged(true);
                clearUserNameAndTokenInLocalStorage();
            } else {
              handleShowAlert("error", "Change Password", "Failed changing Password :", data);
              throw (data);
            }
          })
          .catch(error => {
            console.error(error);
          });
    }

    // Perform password change logic here
    // ...

    // Reset form fields

  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  return (
    <Container className="mt-4">
      <BlockUi blocking={blocking}>
            <Form onSubmit={handleSubmit}>
                <Row className="justify-content-md-center mb-4">
                <Col md="auto">
                    <h2>Change Password</h2>
                </Col>
                </Row>
                <Row className="mb-2 justify-content-center">
                    <Col md="6">
                        <Form.Group as={Row} controlId="formCurrentPassword">
                            <Form.Label column sm="6" className="fw-bold">
                                Current Password :
                            </Form.Label>
                            <Col sm="6">
                                <Form.Control type="password" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} />
                            </Col>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-2 justify-content-center">
                    <Col md="6">
                        <Form.Group as={Row} controlId="formNewPassword">
                            <Form.Label column sm="6" className="fw-bold">
                                New Password :
                            </Form.Label>
                            <Col sm="6">
                                <Form.Control type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)}/>
                            </Col>
                            </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-4 justify-content-center">
                    <Col md="6">
                        <Form.Group as={Row} controlId="formConfirmPassword">
                        <Form.Label column sm="6" className="fw-bold">
                            Confirm New Password :
                        </Form.Label>
                        <Col sm="6">
                            <Form.Control type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                        </Col>
                        </Form.Group>
                    </Col>
                </Row>
                <div className="d-flex justify-content-center">
                    <Button className='me-2' variant="secondary" onClick={handleResetForm}>Cancel</Button>{' '}
                    <Button type="submit" variant="primary" onClick={handleToggleBlocking}>Save</Button>{' '}
                </div>
            </Form>
      </BlockUi>
      {ShowAlert && (
        <AlertModal
          title={AlertTitle}
          message={AlertMessage}
          apiResponse={ApiResponse}
          onClose={handleCloseAlert}
          redirect={passwordChanged ? true : false}
        />
      )}
    </Container>
  );
}

export default ChangePassword;
