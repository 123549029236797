import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import "react-datepicker/dist/react-datepicker.css";
import {
  clearUserNameAndTokenInLocalStorage,
  getUserNameFromLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
import BlockUi from "@availity/block-ui";
import { useForm, Controller } from "react-hook-form";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function ProjectsNewProjectModal(props) {
  const { rowData, onClose, handleShowAlert, customerList } = props;
  const [CustomerList] = useState(customerList);
  const [CustomerId, setCustomerId] = useState("");
  const [ProjectName, setProjectName] = useState("");
  const [Status, setStatus] = useState("A");
  const [blocking, setBlocking] = useState(false);
  const {
    control,
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      CustomerId: "",
      ProjectName: "",
    },
  });
  const handleSubmitNewProject = async () => {
    try {
      await submitNewProject();
      console.log("Success saving Project");
      onClose();
      handleShowAlert("ok", "New Project", "Success saving New Project");
    } catch (error) {
      console.error("Error while saving Project:", error);
      handleShowAlert(
        "error",
        "New Project",
        "Error while saving New Project:",
        error
      );
    }
  };

  const handleToggleBlocking = () => {
    setBlocking((prevState) => !prevState);
  };

  //   const handleSelect = (selectedValue) => {
  //     setStatus(selectedValue);
  //     // You can perform any desired action based on the selected option here.
  //   };

  const submitNewProject = async () => {
    console.log({
      customer_id: CustomerId,
      project_name: ProjectName,
      user_modify: getUserNameFromLocalStorage(),
    });
    try {
      handleToggleBlocking();
      const response = await fetch(config.project_create_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getTokenFromLocalStorage(),
        },
        body: JSON.stringify({
          customer_id: CustomerId,
          project_name: ProjectName,
          user_modify: getUserNameFromLocalStorage(),
          project_status: Status
        }),
      });

      handleToggleBlocking();
      const data = await response.json();
      if (
        data.success !== true &&
        data.message === "Access Denied! Invalid token"
      ) {
        clearUserNameAndTokenInLocalStorage();
      }

      if (data.success !== true) {
        var errorMessages = data.error.map((item) => item.msg);
        // handleShowAlert("error", "New Invoice", "Failed Saving New Invoice :", errorMessages.join("<br>"));
        throw errorMessages;
      }
      if (data.success === true) {
        console.log("Success Submitting Project");
        // handleTrigger(moment().unix());
        return "ok";
      } else {
        throw data;
      }
    } catch (error) {
      console.error("Error while submitting new project: ", error);
      throw error;
    }
  };

  return (
    <Modal show={true} onHide={onClose} size="lg" backdrop="static" centered>
      <BlockUi blocking={blocking}>
        <Form onSubmit={handleSubmit(handleSubmitNewProject)}>
          <Modal.Header closeButton className="px-4">
            <Modal.Title className="ms-auto">New Project</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-2 justify-content-center">
              <Col md="8">
                <Form.Group as={Row} controlId="CustomerId">
                  <Form.Label column sm="5" className="fw-bold">
                    Customer Name :
                  </Form.Label>
                  <Col sm="7">
                    {/* <Form.Select value={CustomerId} onChange={(e) => setCustomerId(e.target.value)}>
                            <option key={""} value="" disabled={true}>Please select customer name</option>
                            {CustomerList.map((items) => (
                              <option key={items.CUSTOMER_ID} value={items.CUSTOMER_ID}>{items.CUSTOMER_NAME}</option>
                            ))}
                      </Form.Select> */}
                    <Controller
                      name="CustomerId"
                      control={control}
                      rules={{ required: "This field is required" }}
                      render={({ field }) => (
                        <Form.Select
                          {...field}
                          onChange={(e) => {
                            setCustomerId(e.target.value);
                            setValue("CustomerId", e.target.value);
                            trigger("CustomerId");
                          }}
                          className={`form-select ${
                            errors.CustomerId ? "is-invalid" : ""
                          }`}
                        >
                          <option key={""} value="" disabled={true}>
                            Please select customer name
                          </option>
                          {CustomerList.map((items) => (
                            <option
                              key={items.CUSTOMER_ID}
                              value={items.CUSTOMER_ID}
                            >
                              {items.CUSTOMER_NAME}
                            </option>
                          ))}
                        </Form.Select>
                      )}
                    />
                    {errors.CustomerId && (
                      <Form.Text className="text-danger">
                        {errors.CustomerId.message}
                      </Form.Text>
                    )}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2 justify-content-center">
              <Col md="8">
                <Form.Group as={Row} controlId="ProjectName">
                  <Form.Label column sm="5" className="fw-bold">
                    Project Name :
                  </Form.Label>
                  <Col sm="7">
                  <Form.Control
                      type="text"
                      placeholder="Project Name"
                      value={ProjectName}
                      onInput={(e) => setProjectName(e.target.value)}
                      className={`form-control ${
                        errors.ProjectName ? "is-invalid" : ""
                      }`}
                      {...register("ProjectName", {
                        pattern: {
                          value: /^.{3,}$/,
                          message: "Minimum 3 Character",
                        },
                        validate: (value) => {
                          return value !== "" || "This field is required";
                        },
                      })}
                    />
                    {errors.ProjectName && (
                      <Form.Text className="text-danger">
                        {errors.ProjectName.message}
                      </Form.Text>
                    )}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2 justify-content-center">
              <Col md="8">
                <Form.Group as={Row} controlId="SLEInvoiceDate">
                  <Form.Label column sm="5" className="fw-bold">
                    Status :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Select
                      value={Status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value="A">Active</option>
                      <option value="I">Inactive</option>
                    </Form.Select>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onClose} variant="secondary">
              Close
            </Button>
            <Button type="submit" variant="primary">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </BlockUi>
    </Modal>
  );
}

export default ProjectsNewProjectModal;
