import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function AlertModal({ title, message, onClose, redirect }) {
  const [show, setShow] = useState(true);
  const navigate = useNavigate();

  const handleClose = () => {
    setShow(false);
    onClose();
    if (redirect === true){
      navigate("/login");
    }
  };

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
      <Modal.Header style={{marginLeft : "15px"}} closeButton>
        <Modal.Title className="ms-auto">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='text-center'>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AlertModal;