import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Pagination from "react-bootstrap/Pagination";
import moment from "moment/moment";
import {
  clearUserNameAndTokenInLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
const config = JSON.parse(process.env.REACT_APP_CONFIG);

function SearchDeliveryOrderEditTable(props) {
  const [data, setData] = useState([]);
  const [headerData] = useState([
    "Brand",
    "Product",
    "This DO Qty",
    "SO Qty",
    "Total DO Qty",
    "Balance Qty",
    "New Qty",
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  //   const { onOnHoldClick } = props;
  //   const { onRestockClick } = props;
  //   const { onCancelClick} = props;
  //   const { searchProps } = props;

  const {
    showAlert,
    rowData,
    selectedMemoNum,
    onRowSelect,
    selectedRows,
    setSelectedRows,
    textInputs,
    setTextInputs,
    onInputChange,
    toggleRowSelection,
    refreshTrigger,
    control,
    register,
    errors,
    reset,
    setValue
  } = props;
  const ROWS_PER_PAGE = 10;
  const totalPages = Math.ceil(data.length / ROWS_PER_PAGE);

  useEffect(() => {
    getData(config.do_get_link, rowData);
  }, [selectedMemoNum, showAlert, refreshTrigger]);

  useEffect(() =>{
    setSelectedRows(data.map((row) => row.PRODUCT_ID));
    if (data.length > 0){
      data.map((row) => {
        const defaultValue = parseFloat(row.THIS_DO_QTY).toFixed(4);
        setTextInputs(prev => ({ ...prev, [row.PRODUCT_ID]: defaultValue }));
        setValue(row.PRODUCT_ID, defaultValue);
      });
    }
  }, [data])

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleToggleBlocking = () => {
    props.onChildToggleChange(false);
  };

  const handleInputChange = (event, rowId) => {
    const { name, value } = event.target;
    setTextInputs({ ...textInputs, [rowId]: value });
    onInputChange(rowId, value); // Call the callback function in the parent component
  };
  const renderTableData = () => {
    const startIndex = (currentPage - 1) * ROWS_PER_PAGE;
    const endIndex = startIndex + ROWS_PER_PAGE;
    return data.slice(startIndex, endIndex).map((row) => {
      const {
        ITEM_ID,
        TOTAL_DO_QTY,
        BRAND,
        PRODUCT_NAME,
        SO_QTY,
        THIS_DO_QTY,
        TOTAL_ORDER_QTY,
        BAL_QTY,
        PRODUCT_ID
      } = row;
      return (
        <tr key={ITEM_ID}>
          <td>
            <Form.Check
              type="checkbox"
              onChange={() => {
                toggleRowSelection(row.PRODUCT_ID);
                reset();
              }}
              checked={selectedRows.includes(row.PRODUCT_ID)}
            />
          </td>
          <td>{BRAND}</td>
          <td>{PRODUCT_NAME}</td>
          <td className="text-end">{THIS_DO_QTY}</td>
          <td className="text-end">{SO_QTY}</td>
          <td className="text-end">{TOTAL_DO_QTY}</td>
          <td className="text-end">{parseFloat(parseFloat(SO_QTY).toFixed(4) - parseFloat(TOTAL_DO_QTY).toFixed(4)).toFixed(4)}</td>
          <td>
            <Form.Control
              type="text"
              name={`textInput-${PRODUCT_ID}`}
              value={textInputs[PRODUCT_ID] || ""}
              className={`text-end form-control ${errors[PRODUCT_ID] ? "is-invalid" : ""}`}
              onInput={(e) => handleInputChange(e, PRODUCT_ID)}
              {...register(PRODUCT_ID, {
                required:
                  selectedRows.includes(PRODUCT_ID) === false
                    ? false
                    : "This field is required",
                pattern: {
                  value: /^(?!0+(\.0*)?$)\d+(\.\d{1,4})?$/,
                  message: "Needs integer or 4 decimal value",
                },
                validate: (value) => {
                  if (!selectedRows.includes(PRODUCT_ID)) return true;
                  const intValue = parseFloat(value).toFixed(4);
                  const maxValue = parseFloat(parseFloat(SO_QTY) - parseFloat(TOTAL_DO_QTY) + parseFloat(THIS_DO_QTY)).toFixed(4);
                  return (
                    parseFloat(intValue) <= parseFloat(maxValue) ||
                    `Enter an integer or 4 decimal value up to ${
                      maxValue
                    }`
                  );
                },
              })}
            />
            {errors[PRODUCT_ID] && (
              <Form.Text className="text-danger">
                {errors[PRODUCT_ID].message}
              </Form.Text>
            )}
            {/* <Form.Control
                            type="text"
                            name={`textInput-${row.ITEM_ID}`}
                            value={textInputs[row.ITEM_ID] || ''}
                            onChange={(e) => handleInputChange(e, row.ITEM_ID)}
                        /> */}
          </td>
        </tr>
      );
    });
  };

  const renderPageItems = () => {
    const pageItems = [];
    for (let i = 1; i <= totalPages; i++) {
      pageItems.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }
    return pageItems;
  };

  const getData = async (url, params = {}) => {
    console.log(params);
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify({
        do_number: params.DO_NUMBER,
      }),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        console.log(jsonData);
        handleToggleBlocking();
        if (jsonData.message === "Access Denied! Invalid token") {
          clearUserNameAndTokenInLocalStorage();
          throw new Error(jsonData.message);
        }
        const newData = jsonData.data.DELIVERY_ORDER_ITEMS.map((item) => {
          const date = moment(item.SO_DATE).format("DD MMM yyyy");
          item.SO_DATE = date;
          return item;
        });
        return newData;
      })
      .then((data) => setData(data))
      .catch((error) => console.error(error));
  };

  if (data.length < 1) {
    return (
      <div className="text-center">
        <h3>No Data</h3>
      </div>
    );
  } else {
    return (
      <div>
        <Table striped bordered hover className="mb-4">
          <thead>
            <tr>
              <th>
                <Form.Check
                  type="checkbox"
                  onChange={() => {
                    reset();
                    if (selectedRows.length === data.length) {
                      setSelectedRows([]);
                    } else {
                      setSelectedRows(data.map((row) => row.PRODUCT_ID));
                    }
                  }}
                  checked={selectedRows.length === data.length}
                />
              </th>
              {headerData.map((header) => (
                <th key={header}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>{renderTableData()}</tbody>
        </Table>
        <Pagination className="d-flex justify-content-center">
          {renderPageItems()}
        </Pagination>
      </div>
    );
  }
}

export default SearchDeliveryOrderEditTable;
