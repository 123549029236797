import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import DatePicker from "react-datepicker";
import moment from 'moment/moment';
import { useNavigate } from 'react-router-dom';
import { clearUserNameAndTokenInLocalStorage, getUserNameFromLocalStorage, getTokenFromLocalStorage } from '../../lib/common';
import BlockUi from '@availity/block-ui';

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function SLEPOModal(props) {
  const { rowData, onClose, handleShowAlert, handleCloseAlert } = props;
  const [SleSoNum] = useState(rowData.SO_NUMBER);
  const [SlePoNum, setSlePoNum] = useState("");
  const [SleMemoNum, setSleMemoNum] = useState("");
  const [SleDoNum, setSleDoNum] = useState("");
  const [SleSoDate] = useState(new Date(rowData.SO_DATE));
  const [SlePoDate, setSlePoDate] = useState(new Date());
  const [SleMemoDate, setSleMemoDate] = useState(new Date());
  const [SleDoDate, setSleDoDate] = useState(new Date());
  const [isIncludeSleDo, setIsIncludeSleDo] = useState(false);
  const [OrderType] = useState(rowData.SO_TYPE_DESC);
  const [blocking, setBlocking] = useState(false);
  const navigate = useNavigate();

  const handleIncludeSleDoCheckbox = (event) => {
    setIsIncludeSleDo(event.target.checked);
  }
  const handleToggleBlocking = () => {
    setBlocking(prevState => !prevState);
  };

  const handleSavePO = async (event) => {
    event.preventDefault();
  
    try {
      if (isIncludeSleDo === true && SleDoNum === "") {
        handleToggleBlocking();
        handleShowAlert("error", "New Purchase Order", "Error while saving PO: ", "DO Number must not be empty when 'Deliver with SLE DO?' is checked");
        return;
      }

      if (rowData.SO_TYPE === "B") {
        await submitMemo();
      } 
  
      await submitPO();
  
      if (isIncludeSleDo === true && SleDoNum !== "") {
        await submitDO();
      }
      handleToggleBlocking();
      console.log("Success saving PO");
      onClose();
      handleShowAlert("ok", "New Purchase Order", "Success saving PO");
    } catch (error) {
      handleToggleBlocking();
      console.error("Error while saving PO:", error);
      handleShowAlert("error", "New Purchase Order", "Error while saving PO: ", error.join("<br>"));
      return;
    }
  };
  
  const submitMemo = async () => {
    try {
      const response = await fetch(config.memo_create_link, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization" : "Bearer " + getTokenFromLocalStorage()
        },
        body: JSON.stringify({
          memo_number: SleMemoNum,
          memo_date: moment(SleMemoDate).format("YYYY-MM-DD"),
          user_modify : getUserNameFromLocalStorage()
        })
      });
  
      const contentType = response.headers.get('content-type');
      const data = contentType && contentType.includes('application/json')
        ? await response.json()
        : await response.text();
      
      if (data.message === 'Access Denied! Invalid token'){
        clearUserNameAndTokenInLocalStorage();
        throw ([data.message]);
      } else if (data.message){
        throw (errorMessages.join("<br>"));
      }
      if (data.errors){
        var errorMessages = data.errors.map(item => item.msg);
        throw (errorMessages);
      }
      if (data.affectedRows > 0) {
        console.log("Success Submitting Memo");
        return "ok";
      } 
    } catch (error) {
      console.error("Error while submitting memo:", error);
      throw error;
    }
  };
  
  const submitPO = async () => {
    try {
      const response = await fetch(config.po_create_link, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization" : "Bearer " + getTokenFromLocalStorage()
        },
        body: JSON.stringify({
          po_type: "S",
          po_number: SlePoNum,
          po_date: moment(SlePoDate).format("YYYY-MM-DD"),
          so_number : SleSoNum,
          so_date: moment(SleSoDate).format("YYYY-MM-DD"),
          memo_number : SleMemoNum !== "" ? SleMemoNum : "",
          user_modify : getUserNameFromLocalStorage()
        })
      });
  
      const contentType = response.headers.get('content-type');
      const data = contentType && contentType.includes('application/json')
        ? await response.json()
        : await response.text();
      
      if (data.message === 'Access Denied! Invalid token'){
        clearUserNameAndTokenInLocalStorage();
        throw (data.message);
      } else if (data.message){
        throw (data.message);
      }
      if (data.errors){
        var errorMessages = data.errors.map(item => item.msg);
        throw (errorMessages.join("<br>"));
      }
      if (data.affectedRows > 0) {
        console.log("Success Submitting PO");
        return "ok";
      } 
    } catch (error) {
      console.error("Error while submitting PO:", error);
      throw error;
    }
  };

  const submitDO = async () => {
    try {
      const response = await fetch(config.do_create_link, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization" : "Bearer " + getTokenFromLocalStorage()
        },
        body: JSON.stringify({
          po_number: SlePoNum,
          do_number : SleDoNum,
          do_date: moment(SleDoDate).format("YYYY-MM-DD"),
          user_modify : getUserNameFromLocalStorage()
        })
      });
  
      const contentType = response.headers.get('content-type');
      const data = contentType && contentType.includes('application/json')
        ? await response.json()
        : await response.text();

      if (data.message === 'Access Denied! Invalid token'){
        clearUserNameAndTokenInLocalStorage();
        throw (data.message);
      } else if (data.message){
        throw (data.message);
      }
      if (data.errors){
        var errorMessages = data.errors.map(item => item.msg);
        throw (errorMessages.join("<br>"));
      }
      if (data.affectedRows > 0) {
        console.log("Success Submitting DO");
        return "ok";
      } 
    } catch (error) {
      console.error("Error while submitting DO:", error);
      throw error;
    }
  }

  return (
    <Modal show={true} onHide={onClose} size="lg" backdrop="static" centered>
      <BlockUi blocking={blocking}>
        <Form onSubmit={handleSavePO}>
          <Modal.Header closeButton className="px-4">
            <Modal.Title className="ms-auto">New Purchase Order</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <Row className="mb-2 justify-content-center">
                <Col md="6">
                  <Form.Group as={Row} controlId="SLESONumber">
                    <Form.Label column sm="6" className="fw-bold">
                      SLE SO Number :
                    </Form.Label>
                    <Col sm="6">
                      <Form.Control readOnly className="no-border" type="text" placeholder="" value={SleSoNum}/>
                    </Col>
                  </Form.Group>
                </Col>

                <Col md="6">
                  <Form.Group as={Row} controlId="SLESODate">
                      <Form.Label column sm="6" className="fw-bold">
                        SLE SO Date :
                      </Form.Label>
                      <Col sm="6">
                        <DatePicker readOnly dateFormat="dd/MM/yyyy" className="form-control no-border" showIcon selected={SleSoDate}/>
                      </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form.Group as={Row} controlId="OrderType">
                    <Form.Label column sm="6" className="fw-bold">
                      Order Type :
                    </Form.Label>
                    <Col sm="6">
                      <Form.Control readOnly className="no-border" type="text" placeholder="" value={OrderType}/>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <hr />
              <Row className="mb-2 justify-content-center">
                <Col md="6">
                  <Form.Group as={Row} controlId="SLEPONumber">
                    <Form.Label column sm="6" className="fw-bold">
                      SLE PO Number :
                    </Form.Label>
                    <Col sm="6">
                      <Form.Control type="text" placeholder="" value={SlePoNum} onInput={(e) => setSlePoNum(e.target.value)}/>
                    </Col>
                  </Form.Group>
                </Col>

                <Col md="6">
                  <Form.Group as={Row} controlId="SLEPODate">
                      <Form.Label column sm="6" className="fw-bold">
                        SLE PO Date :
                      </Form.Label>
                      <Col sm="6">
                        <DatePicker dateFormat="dd/MM/yyyy" className="form-control" showIcon selected={SlePoDate} onChange={(date) => setSlePoDate(date)} />
                      </Col>
                  </Form.Group>
                </Col>
              </Row>
              {rowData.SO_TYPE === "B" ? 
              <Row className="mb-2 justify-content-center">
                <Col md="6">
                  <Form.Group as={Row} controlId="SLEMEMONumber">
                    <Form.Label column sm="6" className="fw-bold">
                      SLE MEMO Number :
                    </Form.Label>
                    <Col sm="6">
                      <Form.Control type="text" placeholder="" value={SleMemoNum} onInput={(e) => setSleMemoNum(e.target.value)}/>
                    </Col>
                  </Form.Group>
                </Col>

                <Col md="6">
                  <Form.Group as={Row} controlId="SLEMEMODate">
                      <Form.Label column sm="6" className="fw-bold">
                        SLE MEMO Date :
                      </Form.Label>
                      <Col sm="6">
                        <DatePicker dateFormat="dd/MM/yyyy" className="form-control" showIcon selected={SleMemoDate} onChange={(date) => setSleMemoDate(date)} />
                      </Col>
                  </Form.Group>
                </Col>
              </Row> : <></>}
              <hr />
              <Row className="justify-content-center mb-2">
                <Col className="fw-bold">
                  <Form.Check 
                    type="checkbox"
                    id="deliver_with_sle_do_checkbox"
                    label="Deliver with SLE DO?"
                    checked={isIncludeSleDo}
                    onChange={handleIncludeSleDoCheckbox}
                  />
                </Col>
              </Row>
              <Row className="mb-2 justify-content-center">
                  <Col md="6">
                    <Form.Group as={Row} controlId="SLEDONumber">
                      <Form.Label column sm="6" className="fw-bold">
                        SLE DO Number :
                      </Form.Label>
                      <Col sm="6">
                        <Form.Control type="text" placeholder="" value={SleDoNum} onInput={(e) => setSleDoNum(e.target.value)}/>
                      </Col>
                    </Form.Group>
                  </Col>

                  <Col md="6">
                    <Form.Group as={Row} controlId="SLEMEMODate">
                        <Form.Label column sm="6" className="fw-bold">
                          SLE DO Date :
                        </Form.Label>
                        <Col sm="6">
                          <DatePicker dateFormat="dd/MM/yyyy" className="form-control" showIcon selected={SleDoDate} onChange={(date) => setSleDoDate(date)} />
                        </Col>
                    </Form.Group>
                  </Col>
                </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onClose} variant="secondary">Cancel</Button>
            <Button type="submit" variant="primary" onClick={handleToggleBlocking}>Save</Button>
          </Modal.Footer>
        </Form>
      </BlockUi>
    </Modal>
  );
}

export default SLEPOModal;