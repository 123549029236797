import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';
import moment from 'moment/moment';
import { clearUserNameAndTokenInLocalStorage, getTokenFromLocalStorage } from '../../lib/common';
const config = JSON.parse(process.env.REACT_APP_CONFIG);

function DeliveryDateRemarksTable(props) {
  const [data, setData] = useState([]);
  const { rowData, trigger } = props;
  const [headerData] = useState(["Date", "Updated By", "Remarks"]);
  const [currentPage, setCurrentPage] = useState(1);
  const ROWS_PER_PAGE = 5;
  const totalPages = Math.ceil(data.length / ROWS_PER_PAGE);

  useEffect(() => {
    getData(config.deliverydate_followup_list_link, rowData);
  }, [rowData, trigger]);

  const handleToggleBlocking = () => {
    props.onChildToggleChange(false);
  };


  const getData = async (url, params = {}) => {
    await fetch(url,{
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Authorization" : "Bearer " + getTokenFromLocalStorage()
      },
      body : JSON.stringify(params.length === 0 ? params : {
        po_number : params.PO_NUMBER
      })
    })
    .then(response => response.json())
    .then (jsonData => {
      handleToggleBlocking();
      if (jsonData.message === 'Access Denied! Invalid token'){
        clearUserNameAndTokenInLocalStorage();
        throw new Error (jsonData.message);
      }
      if (data.errors){
        var errorMessages = data.errors.map(item => item.msg);
        throw errorMessages.join("<br>");
      } 
      const newData = jsonData.map(item => {
        const date = moment(item.DATE_MODIFY).format("DD MMM yyyy");
        item.DATE_MODIFY = date;
        return item;
      });
      return newData;
    })
    .then(data => setData(data))
    .catch(error => console.error(error));
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderTableData = () => {
    const startIndex = (currentPage - 1) * ROWS_PER_PAGE;
    const endIndex = startIndex + ROWS_PER_PAGE;
    return data.slice(startIndex, endIndex).map((row) => {
      const {DATE_FUP_HIST_ID, DATE_MODIFY, USER_MODIFY, COMMENTS} = row;
      return (
        <tr key={DATE_FUP_HIST_ID}>
          <td style={{ minWidth: '120px' }}>{DATE_MODIFY}</td>
          <td style={{ minWidth: '150px' }}>{USER_MODIFY}</td>
          <td>{COMMENTS}</td>
        </tr>
      );
    });
  };

  const renderPageItems = () => {
    const pageItems = [];
    for (let i = 1; i <= totalPages; i++) {
      pageItems.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
          {i}
        </Pagination.Item>
      );
    }
    return pageItems;
  };

  if (data.length<1){
    return(
      <div className="text-center"><h3>No Data</h3></div>
    )
  } else {
    return (
      <div>
        <Table striped bordered hover className="mb-4">
          <thead>
            <tr>{headerData.map((header) => <th key={header}>{header}</th>)}</tr>
          </thead>
          <tbody>{renderTableData()}</tbody>
        </Table>
        <Pagination className="d-flex justify-content-center" >{renderPageItems()}</Pagination>
      </div>
    );
  };
}

export default DeliveryDateRemarksTable;