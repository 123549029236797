import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import ManageBulkOrderMemoNewTable from "./ManageBulkOrderMemo_NewTable";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import {
    clearUserNameAndTokenInLocalStorage,
    getUserNameFromLocalStorage,
    getTokenFromLocalStorage,
} from "../../lib/common";
import BlockUi from "@availity/block-ui";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function ManageBulkOrderNewModal(props) {
    const { rowData, onClose, handleShowAlert, handleCloseAlert } = props;
    const [SleSoNum] = useState(rowData.SO_NUMBER);
    const [SlePoNum, setSlePoNum] = useState(rowData.PO_NUMBER);
    const [SleMemoNum, setSleMemoNum] = useState("");
    const [SleDoNum, setSleDoNum] = useState("");
    const [CustomerId] = useState(rowData.CUSTOMER_ID);
    const [CustomerName, setCustomerName] = useState(rowData.CUSTOMER_NAME);
    const [ProjectId] = useState(rowData.PROJECT_ID);
    const [ProjectName, setProjectName] = useState(rowData.PROJECT_NAME);
    const [PaymentTerm] = useState(rowData.PAYMENT_TERM);
    const [SleSoDate] = useState(new Date(rowData.SO_DATE));
    const [SlePoDate, setSlePoDate] = useState(new Date(rowData.PO_DATE));
    const [SleMemoDate, setSleMemoDate] = useState(new Date());
    const [PoDate, setPoDate] = useState(new Date());
    const [isIncludeSleDo, setIsIncludeSleDo] = useState(false);
    const [OrderType] = useState(rowData.SO_TYPE_DESC);
    const [Remarks, setRemarks] = useState("");
    const [Trigger, setTrigger] = useState("");
    const [blocking, setBlocking] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [textInputs, setTextInputs] = useState({});
    const [selectedData, setSelectedData] = useState([]);
    const navigate = useNavigate();

    const handleIncludeSleDoCheckbox = (event) => {
        setIsIncludeSleDo(event.target.checked);
    };
    const handleToggleBlocking = () => {
        setBlocking((prevState) => !prevState);
    };

    const handleToggleFromChild = (newValue) => {
        setBlocking(newValue);
    };

    const handleSaveNewMemo = async (event) => {
        event.preventDefault(); 
        try {
            var item_qtis = Object.values(textInputs);
            if (item_qtis.length === 0) {
                handleToggleBlocking();
                throw ["No order item selected!"]
            }
            handleToggleBlocking();
            await submitNewMemo();
            console.log("Success saving New Memo");
            onClose();
            handleShowAlert(
                "ok",
                "New New Memo",
                "Success saving New Memo"
            );
        } catch (error) {
            handleToggleBlocking();
            console.error("Error while saving New Memo:", error);
            handleShowAlert(
                "error",
                "New New Memo",
                "Error while saving New Memo: ",
                error.join("<br>")
            );
            return;
        }
    };

    const submitNewMemo = async () => {
        try {
            var item_qtis = Object.values(textInputs);
            const response = await fetch(config.memo_create_link, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + getTokenFromLocalStorage(),
                },
                body: JSON.stringify({
                    so_number: SleSoNum,
                    po_number: SlePoNum,
                    product_id : selectedRows,
                    item_qty : item_qtis.map((item_qty) => parseInt(item_qty))
                }),
            });

            const data = await response.json();
            console.log("submit new memo response : ");
            console.log(data);
            if (
                data.success !== true &&
                data.message === "Access Denied! Invalid token"
            ) {
                clearUserNameAndTokenInLocalStorage();
            }

            if (data.success !== true) {
                var errorMessages = data.error.map((item) => item.msg);
                // handleShowAlert("error", "New Invoice", "Failed Saving New Invoice :", errorMessages.join("<br>"));
                throw errorMessages;
            }
            if (data.success === true) {
                console.log("Success Submitting New Memo");
                // handleTrigger(moment().unix());
                return "ok";
            } else {
                throw data;
            }
        } catch (error) {
            console.error("Error while submitting New Memo:", error);
            throw error;
        }
    };



    const toggleRowSelection = (rowId) => {
        if (selectedRows.includes(rowId)) {
            setSelectedRows(selectedRows.filter((id) => id !== rowId));
        } else {
            setSelectedRows([...selectedRows, rowId]);
        }
    };

    const handleInputChange = (rowId, value) => {
        setTextInputs({ ...textInputs, [rowId]: value });
    };

    const handleSaveSelectedData = (event) => {
        event.preventDefault();
        var item_qtis = Object.values(textInputs);
        var dataSend = {
            product_id : selectedRows,
            item_qty : item_qtis.map((item_qty) => parseInt(item_qty))
        };

        
        // You can perform additional actions with the selected data here
    };

    return (
        <Modal show={true} onHide={onClose} size="lg" backdrop="static" centered>
            <BlockUi blocking={blocking}>
                <Form onSubmit={handleSaveNewMemo}>
                    <Modal.Header closeButton className="px-4">
                        <Modal.Title className="ms-auto">New Memo (Bulk Order)</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="mb-2 justify-content-center" style={{paddingLeft : "80px"}}>
                            <Col md="6">
                                <Form.Group as={Row} controlId="SLESONumber">
                                    <Form.Label column sm="5" className="fw-bold">
                                        SLE SO Number :
                                    </Form.Label>
                                    <Col sm="6">
                                        <Form.Control
                                            readOnly
                                            className="no-border"
                                            type="text"
                                            placeholder=""
                                            value={SleSoNum}
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>

                            <Col md="6">
                                <Form.Group as={Row} controlId="SLESODate">
                                    <Form.Label column sm="5" className="fw-bold">
                                        SLE SO Date :
                                    </Form.Label>
                                    <Col sm="6">
                                        <DatePicker
                                            readOnly
                                            dateFormat="dd/MM/yyyy"
                                            className="form-control no-border"
                                            showIcon
                                            selected={SleSoDate}
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-2 justify-content-center" style={{paddingLeft : "80px"}}>
                            <Col md="6">
                                <Form.Group as={Row} controlId="SLESONumber">
                                    <Form.Label column sm="5" className="fw-bold">
                                        SLE PO Number :
                                    </Form.Label>
                                    <Col sm="6">
                                        <Form.Control
                                            readOnly
                                            className="no-border"
                                            type="text"
                                            placeholder=""
                                            value={SlePoNum}
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>

                            <Col md="6">
                                <Form.Group as={Row} controlId="SLESODate">
                                    <Form.Label column sm="5" className="fw-bold">
                                        SLE PO Date :
                                    </Form.Label>
                                    <Col sm="6">
                                        <DatePicker
                                            readOnly
                                            dateFormat="dd/MM/yyyy"
                                            className="form-control no-border"
                                            showIcon
                                            selected={SlePoDate}
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-2" style={{paddingLeft : "80px"}}>
                            <Col md="12">
                                <Form.Group as={Row} controlId="SLESONumber">
                                    <Form.Label column sm="3" className="fw-bold" >
                                        Customer Name :
                                    </Form.Label>
                                    <Col sm="6" style={{marginLeft:"-33px"}}>
                                        <Form.Control
                                            readOnly
                                            className="no-border"
                                            type="text"
                                            placeholder=""
                                            value={CustomerName}
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-2" style={{paddingLeft : "80px"}}>
                            <Col md="12">
                                <Form.Group as={Row} controlId="SLESODate">
                                    <Form.Label column sm="3" className="fw-bold">
                                        Project Name:
                                    </Form.Label>
                                    <Col sm="6" style={{marginLeft:"-33px"}}>
                                        <Form.Control
                                            readOnly
                                            className="no-border"
                                            type="text"
                                            placeholder=""
                                            value={ProjectName}
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-2" style={{paddingLeft : "80px"}}>
                            <Col md="6">
                                <Form.Group as={Row} controlId="OrderType">
                                    <Form.Label column sm="5" className="fw-bold">
                                        Order Type :
                                    </Form.Label>
                                    <Col sm="6">
                                        <Form.Control
                                            readOnly
                                            className="no-border"
                                            type="text"
                                            placeholder=""
                                            value={OrderType}
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col md="6">
                                <Form.Group as={Row} controlId="OrderType">
                                    <Form.Label column sm="5" className="fw-bold">
                                        Payment Term :
                                    </Form.Label>
                                    <Col sm="6">
                                        <Form.Control
                                            readOnly
                                            className="no-border"
                                            type="text"
                                            placeholder=""
                                            value={PaymentTerm}
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                        </Row>
                        <hr />
                        <Row className="mb-4 align-items-center">
                            <Col sm="12" className="text-center">
                                <Form.Label>[ Order Item ]</Form.Label>
                            </Col>
                        </Row>
                        <ManageBulkOrderMemoNewTable
                            rowData={rowData}
                            onChildToggleChange={handleToggleFromChild}
                            onRowSelect={toggleRowSelection}
                            onInputChange={handleInputChange}
                            selectedRows={selectedRows}
                            setSelectedRows = {setSelectedRows}
                            textInputs = {textInputs}
                            setTextInputs = {setTextInputs}
                            toggleRowSelection = {toggleRowSelection}
                        />
                        {/* <hr />
                        <div>
                            <h2>Selected Data:</h2>
                            <pre>{JSON.stringify(selectedData, null, 2)}</pre>
                        </div> */}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={onClose} variant="secondary">
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            variant="primary"
                        >
                            Save
                        </Button>
                    </Modal.Footer>
                </Form>
            </BlockUi>
        </Modal>
    );
}

export default ManageBulkOrderNewModal;
