import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Pagination from "react-bootstrap/Pagination";
import moment from "moment/moment";
import {
  clearUserNameAndTokenInLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
const config = JSON.parse(process.env.REACT_APP_CONFIG);

function ManagePoTable(props) {
  const [data, setData] = useState([]);
  const [headerData] = useState([
    "SLE PO Number",
    "PO Type",
    "PO Date",
    "Supplier Name",
    "SLE SO Number",
    "SO Type",
    "Action",
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const { onOnHoldClick } = props;
  const { onRestockClick } = props;
  const { onCancelClick } = props;
  const { onViewClick } = props;
  const { onDeleteClick } = props;
  const { searchProps } = props;
  const { showAlert } = props;
  const ROWS_PER_PAGE = 10;
  const totalPages = Math.ceil(data.length / ROWS_PER_PAGE);

  useEffect(() => {
    if (
      searchProps.SleSoNum ||
      searchProps.SlePoNum ||
      searchProps.SleSoDateFrom ||
      searchProps.SleSoDateTo ||
      searchProps.SlePoDateFrom ||
      searchProps.SlePoDateTo ||
      searchProps.CustomerName ||
      searchProps.ProjectName
    ) {
      getData(config.po_list_link, searchProps);
    } else {
      getData(config.po_list_link);
    }
  }, [searchProps, showAlert]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleToggleBlocking = () => {
    props.onChildToggleChange(false);
  };

  const renderTableData = () => {
    const startIndex = (currentPage - 1) * ROWS_PER_PAGE;
    const endIndex = startIndex + ROWS_PER_PAGE;
    return data.slice(startIndex, endIndex).map((row) => {
      const {
        ROW_KEY,
        SO_NUMBER,
        SO_TYPE_DESC,
        PO_NUMBER,
        PO_TYPE,
        PO_DATE,
        SUPPLIER_NAME,
        PROJECT_NAME,
        MEMO_CNT,
        GRN_CNT,
        CAN_UPDATE
      } = row;
      return (
        <tr key={ROW_KEY}>
          <td>{PO_NUMBER}</td>
          <td>{PO_TYPE}</td>
          <td>{moment(PO_DATE).add(8, "hour").format("DD MMM yyyy")}</td>
          <td>{SUPPLIER_NAME}</td>
          <td>{SO_NUMBER}</td>
          <td>{SO_TYPE_DESC}</td>
          <td style={{ width: "299px" }}>
            <Button
              style={{ marginRight: "5px" }}
              variant="primary"
              onClick={() => onViewClick(row)}
              // disabled={MEMO_CNT > 0 ? true : false}
            >
              View
            </Button>
            <Button
              style={{ marginRight: "5px" }}
              variant="primary"
              // onClick={() => onDeleteClick(row)}
              disabled={GRN_CNT > 0  || CAN_UPDATE=='N' ? true : false}
            >
              Edit
            </Button>
            <Button
              variant="primary"
              onClick={() => onDeleteClick(row)}
              disabled={GRN_CNT > 0  || CAN_UPDATE=='N' ? true : false}
            >
              Delete
            </Button>
          </td>
        </tr>
      );
    });
  };

  const renderPageItems = () => {
    const pageItems = [];
    for (let i = 1; i <= totalPages; i++) {
      pageItems.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }
    return pageItems;
  };

  const getData = async (url, params = {}) => {
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify(
        params.length === 0
          ? params
          : {
              so_number: params.SleSoNum,
              po_number: params.SlePoNum,
              so_date_from: params.SleSoDateFrom,
              so_date_to: params.SleSoDateTo,
              po_date_from: params.SlePoDateFrom,
              po_date_to: params.SlePoDateTo,
              customer_id: params.CustomerName,
              project_id: params.ProjectName,
            }
      ),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        handleToggleBlocking();
        console.log(jsonData);
        if (jsonData.message === "Access Denied! Invalid token") {
          clearUserNameAndTokenInLocalStorage();
          throw new Error(jsonData.message);
        }
        const newData = jsonData.data.map((item) => {
          const date = moment(item.SO_DATE).format("DD MMM yyyy");
          item.SO_DATE = date;
          item.ROW_KEY = [...Array(10)]
            .map(() => (~~(Math.random() * 36)).toString(36))
            .join("");
          return item;
        });
        return newData;
      })
      .then((data) => setData(data))
      .catch((error) => console.error(error));
  };

  if (data.length < 1) {
    return (
      <div className="text-center">
        <h3>No Data</h3>
      </div>
    );
  } else {
    return (
      <div>
        <Table striped bordered hover className="mb-4">
          <thead>
            <tr>
              {headerData.map((header) => (
                <th key={header}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>{renderTableData()}</tbody>
        </Table>
        <Pagination className="d-flex justify-content-center">
          {renderPageItems()}
        </Pagination>
      </div>
    );
  }
}

export default ManagePoTable;
