import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import "react-datepicker/dist/react-datepicker.css";
import {
  clearUserNameAndTokenInLocalStorage,
  getUserNameFromLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
import BlockUi from "@availity/block-ui";
import { useForm, Controller } from "react-hook-form";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function SuppliersEditSupplierModal(props) {
  const { rowData, onClose, handleShowAlert } = props;
  const [SupplierId] = useState(rowData.SUPPLIER_ID);
  const [SupplierName, setSupplierName] = useState(rowData.SUPPLIER_NAME);
  const [SupplierStatus, setSupplierStatus] = useState(rowData.SUPPLIER_STATUS);
  const [blocking, setBlocking] = useState(false);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      SupplierName: rowData.SUPPLIER_NAME,
    },
  });
  const handleUpdateSupplier = async () => {
    try {
      await submitUpdateSupplier();
      console.log("Success editing Supplier");
      onClose();
      handleShowAlert("ok", "Edit Supplier", "Success editing Supplier");
    } catch (error) {
      console.error("Error while editing Supplier:", error);
      handleShowAlert(
        "error",
        "Edit Supplier",
        "Error while editing Supplier:",
        error
      );
    }
  };

  const handleToggleBlocking = () => {
    setBlocking((prevState) => !prevState);
  };

  //   const handleSelect = (selectedValue) => {
  //     setStatus(selectedValue);
  //     // You can perform any desired action based on the selected option here.
  //   };

  const submitUpdateSupplier = async () => {
    try {
      handleToggleBlocking();
      const response = await fetch(config.supplier_update_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getTokenFromLocalStorage(),
        },
        body: JSON.stringify({
          supplier_id: SupplierId,
          supplier_name: SupplierName,
          supplier_status: SupplierStatus,
          user_modify: getUserNameFromLocalStorage(),
        }),
      });

      const data = await response.json();
      handleToggleBlocking();
      if (
        data.success !== true &&
        data.message === "Access Denied! Invalid token"
      ) {
        clearUserNameAndTokenInLocalStorage();
      }
      if (data.success !== true) {
        var errorMessages = data.error.map((item) => item.msg);
        // handleShowAlert("error", "New Invoice", "Failed Saving New Invoice :", errorMessages.join("<br>"));
        throw errorMessages;
      }
      if (data.success === true) {
        console.log("Success Updating Supplier");
        // handleTrigger(moment().unix());
        return "ok";
      } else {
        throw data;
      }
    } catch (error) {
      console.error("Error while updating Supplier:", error);
      throw error;
    }
  };

  return (
    <Modal show={true} onHide={onClose} size="lg" backdrop="static" centered>
      <BlockUi blocking={blocking}>
        <Form onSubmit={handleSubmit(handleUpdateSupplier)}>
          <Modal.Header closeButton className="px-4">
            <Modal.Title className="ms-auto">Edit Supplier</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-2 justify-content-center">
              <Col md="6">
                <Form.Group as={Row} controlId="SupplierName">
                  <Form.Label column sm="6" className="fw-bold">
                    Supplier Name :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      type="text"
                      placeholder=""
                      value={SupplierName}
                      onInput={(e) => setSupplierName(e.target.value)}
                      className={`form-control ${
                        errors.SupplierName ? "is-invalid" : ""
                      }`}
                      {...register("SupplierName", {
                        pattern: {
                          value: /^.{3,}$/,
                          message: "Minimum 3 Character",
                        },
                        validate: (value) => {
                          return value !== "" || "This field is required";
                        },
                      })}
                    />
                    {errors.SupplierName && (
                      <p className="text-danger">
                        {errors.SupplierName.message}
                      </p>
                    )}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2 justify-content-center">
              <Col md="6">
                <Form.Group as={Row} controlId="SLEInvoiceDate">
                  <Form.Label column sm="6" className="fw-bold">
                    Status :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Select
                      value={SupplierStatus}
                      onChange={(e) => setSupplierStatus(e.target.value)}
                    >
                      <option value="A">Active</option>
                      <option value="I">Inactive</option>
                    </Form.Select>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onClose} variant="secondary">
              Close
            </Button>
            <Button type="submit" variant="primary">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </BlockUi>
    </Modal>
  );
}

export default SuppliersEditSupplierModal;
